const { html } = require('@forgjs/noframework');

const Links = () => {
  const DomElement = html`<div class="urlLinks">
    <a href="https://www.facebook.com/spandita.malik" target="_blank">
      <img src="/img/fb.png">
    </a>
    <a href="https://www.instagram.com/spanditamalik/?hl=en" target="_blank">
      <img src="/img/insta.png">
    </a>
    <a href="https://www.linkedin.com/in/malikspandita" target="_blank">
      <img src="/img/lin.png">
    </a>
  </div>`;

  return DomElement;
};

module.exports = Links;
