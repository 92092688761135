const { html } = require('@forgjs/noframework');
const link = require('./link');

const Nav2 = () => {
  const DomElement = html`
  <nav class="nav">
    <ul class="nav__links">
      <div class="nav__left">
        ${link('/commercials', html`<li class="nav__name nav__link">Spandita Malik</li>`)}
      </div>
      <div class="nav__right">
        ${link('/projects', html`<li class="nav__link">Website</li>`)}
        ${link('/commercial/videos', html`<li class="nav__link">Videos</li>`)}
        ${link('/commercial/cv', html`<li class="nav__link">Cv</li>`)}
        ${link('/commercial/about-me', html`<li class="nav__link">About me</li>`)}
      </div>
    </ul>
  </nav>`;

  return DomElement;
};

module.exports = Nav2;
