const { html } = require('@forgjs/noframework');
const Nav = require('../../components/Nav');
const eventManager = require('../eventManager');
const Overlay = require('../Overlay');
const projects = require('../../projects');
const New = require('./new');
const Footer = require('../../components/Footer');

const MONTHS = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};

const toDate = (str) => {
  const split = str.split(' ');
  const month = MONTHS[split[0]];
  const day = parseInt(split[1], 10);
  const year = parseInt(split[2], 10);
  return new Date(year, month, day);
};

const news = [
  {
    subtitle: "July 7, 2023",
    title: "‘Jāḷī : Meshes of Resistance’ Malik’s first museum solo exhibition opens at Kemper Museum of Contemporary Art",
    url: "https://www.kemperart.org/exhibition/spandita-malik-solo-exhibition",
    img: 'img/news/10.jpg',
  },
  {
    subtitle: "March 1, 2023",
    title: "Vadhu featured in Marie Claire Korea 30th Anniversary Issue- World Report",
    url: "img/news/9news.pdf",
    img: 'img/news/9.jpg',
  },
  {
    subtitle: "February 16, 2023",
    title: "‘Vadhu: The Embroidered Bride’ Spandita Malik solo exhibition opens at Karl and Helen Burger Gallery, Kean University",
    url: "https://galleries.kean.edu/exhibitions/vadhu-embroidered-bride",
    img: 'img/news/8.jpg',
  },
  {
    subtitle: "January 1, 2023",
    title: "‘Nuzrat Praween’ featured as cover story for Granta Magazine",
    url: "https://www.grantahebrew.com/nari/",
    img: 'img/news/6.jpg',
  },
  {
    subtitle: "January 1, 2023",
    title: "‘Nā́rī’ reviewed in NYTM Talking Textiles, Issue 7 Character",
    url: "img/news/5news.pdf",
    img: 'img/news/5.jpg',
  },
  {
    subtitle: "November 1, 2022",
    title: "Nā́rī featured in Marie Claire Korea November 2022 Issue",
    url: "img/news/4news.pdf",
    img: 'img/news/4.jpg',
  },
  {
    subtitle: "September 3, 2022",
    title: "‘Nā́rī’ Spandita Malik solo opens at Blue Sky Gallery, Oregon Center for the Photographic Arts, Portland, OR",
    url: "https://www.blueskygallery.org/exhibitions/archives/2022/spandita-malik",
    img: 'img/news/3.jpg',
  },
  {
    subtitle: "August 4, 2022",
    title: "Malik wins Lensculture Critics’ Choice 2022",
    url: "https://www.lensculture.com/articles/spandita-malik-na-ri",
    img: 'img/news/2.jpg',
  },
  {
    subtitle: "May 14, 2022",
    title: "The Hindu Review “Through the lens, darkly”",
    url: "https://www.thehindu.com/life-and-style/through-the-lens-darkly/article65356303.ece",
    img: 'img/news/1.jpg',
  },
  {
    subtitle: "May 22, 2021",
    title: "Visual Arts Center of New Jersey Virtual Gala Auction",
    url: "http://32auctions.com/PaintTheTown2021",
    img: 'img/news/news22.jpg',
  },
  {
    subtitle: "May 27, 2021",
    title: "Art Spiel Photo Story Reviews works in ‘Shifting Sands’ at ChaShaMa",
    url: "https://artspiel.org/shifting-sands-at-chashama/",
    img: 'img/news/news23.jpg',
  },
  {
    subtitle: "June 1, 2021",
    title: "In Between Yesterday Today and Tomorrow, En Foco's exhibition of the 2021 Photography Fellowship awardees",
    url: "https://enfoco.org/in-between-yesterday-today-and-tomorrow/",
    img: 'img/news/news24.jpg',
  },
  {
    subtitle: "June 1, 2021",
    title: "Nueva Luz features photography from the current En Foco Fellows",
    url: "https://enfoco.org/nueva-luz-archive/",
    img: 'img/news/news25.jpg',
  },
  {
    subtitle: "June 8, 2021",
    title: "Artsy features Malik’s work as Rising Contemporary Artists Using Traditional Craft Techniques",
    url: "https://www.artsy.net/article/artsy-editorial-6-rising-contemporary-artists-traditional-craft-techniques",
    img: 'img/news/news26.jpg',
  },
  {
    subtitle: "August 16, 2021",
    title: "Spandita malik talks for the CALTA21 Educational Program, A MODEL FOR BRIDGING MUSEUMS AND IMMIGRANT ENGLISH LEARNERS",
    url: "https://www.youtube.com/watch?v=HQLJInbMDJ8",
    img: 'img/news/news27.jpg',
  },
  {
    subtitle: "September 8, 2021",
    title: "Photo London 2021, indigo+madder brings works by Tenzing Dakpa, Srinivas Kuruganti and Spandita Malik",
    url: "https://indigoplusmadder.com/london-art-exhibitions/indigoplusmadder/artfairs/photolondon",
    img: 'img/news/news28.jpg',
  },
  {
    subtitle: "October 3, 2021",
    title: "Lucien De Guise reviews ‘Nari’: Of Photography and perspective at Photo London 2021",
    url: "https://api.nst.com.my/lifestyle/sunday-vibes/2021/10/733131/photography-and-perspective-photo-london-2021",
    img: 'img/news/news29.jpg',
  },
  {
    subtitle: "October 13, 2021",
    title: "‘Vadhu: The Embroidered Bride’, Spandita Malik solo exhibition opens at Baxter St CCNY",
    url: "https://www.baxterst.org/events/vadhu-the-embroidered-bride/",
    img: 'img/news/news30.jpg',
  },
  {
    subtitle: "November 9, 2021 ",
    title: "Buzzfeed News reviews new series ‘Vadhu: The Embroidered Bride’",
    url: "https://www.buzzfeednews.com/article/piapeterson/spandita-malik-photography-women-india",
    img: 'img/news/news31.jpg',
  },
  {
    subtitle: "November 14, 2021",
    title: "Artnet News, Editor’s Pick: ‘Vadhu: The Embroidered Bride’, Spandita Malik solo exhibition ",
    url: "https://news.artnet.com/art-world/editors-picks-november-1-2021-2022645",
    img: 'img/news/news32.jpg',

  },
  {
    subtitle: "November 19, 2021",
    title: "KCAI Biennial ‘Beautiful World(s)’ presents works by AICAD Fellow Spandita Malik",
    url: "https://kcai.edu/hr-block-artspace/artspace-events/beautiful-worlds-kcai-biennial/",
    img: 'img/news/news33.jpg',

  },
  {
    subtitle: "January 13, 2022",
    title: "Spandita Malik selected as The30: New and Emerging Photographers to Watch",
    url: "https://2022.the30photographers.com/p/17",
    img: 'img/news/news34.jpg',
  },
  {
    subtitle: "March 1, 2022",
    title: "Malaika Byng reviews Malik’s work in Crafts Magazine: ‘The Power of the Needle’",
    url: "https://www.craftscouncil.org.uk/crafts/crafts-magazine/issue-293",
    img: 'img/news/news35.jpg',
  },
  {
    subtitle: "March 11, 2022",
    title: "‘say the dream was real and the wall imaginary,’ A group exhibition curated by Joseph R. Wolin opens at Jane Lombard Gallery",
    url: "https://www.janelombardgallery.com/current",
    img: 'img/news/news36.jpg',
  },
  {
    subtitle: "May 14, 2021",
    title: "‘Shifting Sands’, showcasing artists from the NYFA Immigrant Artist Mentoring Program",
    url: "https://chashama.org/event/shifting-sands/ ",
    img: 'img/news/news21.jpg',
  },
  {
    title: 'Ayesha Majeed reviews ‘Nari’ for The News',
    subtitle: 'April 18, 2021',
    img: 'img/news/news20.jpg',
    url: 'https://www.thenews.com.pk/tns/detail/821207-the-art-of-satire',
  },
  {
    title: 'Artnet News, Editor’s Pick:“Sexual Justice Symposium” at the New School, New York',
    subtitle: 'April 5, 2021',
    img: 'img/news/news19.jpg',
    url: 'https://news.artnet.com/art-world/editors-picks-april-5-2021-1955582',
  },
  {
    title: 'Gender & Sexualities Studies Institute\'s 2021 Gender Matters Symposium Panelist',
    subtitle: 'April 5, 2021',
    img: 'img/news/news18.jpg',
    url: 'https://event.newschool.edu/sexualjusticesymposium',
  },
  {
    title: 'Lavina Melwani reviews ‘Nari’ for CNBCTV18',
    subtitle: 'March 17, 2021',
    img: 'img/news/news17.jpg',
    url: 'https://www.cnbctv18.com/views/three-artists-discover-a-larger-community-in-pandemic-8624421.htm',
  },
  {
    title: '2021 En Foco Fellowship Awardees',
    subtitle: 'March 10, 2021',
    img: 'img/news/news16.png',
    url: 'https://enfoco.org/fellowship-winners/',
  },
  {
    title: 'Malik presents ‘Sarfaraz’ at ‘Diasporic Rhizome’, a virtual exhibition curated by Faisal Anwar, Ambia Trasi, Brendan Fernandes for the South Asia Institute',
    subtitle: 'March 1, 2021',
    img: 'img/news/news15.jpg',
    url: 'https://www.saichicago.org/art/diasporic-rhizome',
  },
  {
    title: 'Winter/Spring exhibitions open at Visual Art Center',
    subtitle: 'February 5, 2021',
    img: 'img/news/news14.jpg',
    url: 'https://www.nj.com/independentpress/2021/02/winterspring-exhibitions-open-at-visual-art-center.html',
  },
  {
    title: 'What We Become When We Are Unbound, a group exhibition of work by Jillian M Rock, Mimi Bai, Sarah K. Khan, Spandita Malik, and Priscilla Dobler Dzul',
    subtitle: 'February 13, 2021',
    img: 'img/Show announcement PES.jpg',
    url: 'https://www.projectforemptyspace.org',
  },
  {
    title: 'Sara VanDerBeek features Malik’s work as one of her Favorite Artworks from Week 3 of NADA ART FAIR',
    subtitle: 'May 20, 2020',
    img: 'img/news/news13bis.jpg',
    url: 'https://www.newartdealers.org/features/sara-vanderbeek-on-her-favorite-artworks-from-week-3-of-fair',
  },
  {
    title: '‘Spandita Malik: Threads of Identity’ opens at The Visual Art Center of New Jersey',
    subtitle: 'February 06, 2021',
    img: 'img/VACNJ.jpg',
    url: 'https://www.artcenternj.org/spandita-malik-threads-of-identity/',
  },
  {
    title: 'Artnet News, Editor’s Pick: “\\ ˈtəch \\*” at Marisa Newman Project',
    subtitle: 'January 19, 2021',
    img: 'img/artnet news.jpg',
    url: 'https://news.artnet.com/art-world/editors-picks-january-18-2021-1936256',
  },
  {
    title: 'Malik’s work displayed in a group show “\\ ˈtəch \\*” at Marisa Newman Projects, New York',
    subtitle: 'November 23, 2020',
    img: 'img/Marisa Newman.jpg',
    url: 'http://www.marisanewman.com',
  },
  {
    title: 'PHmuseum 2020 Women Photographers Grant-Honorable Mention: Spandita Malik',
    subtitle: 'November 24, 2020',
    img: 'img/PhMuseum.jpg',
    url: 'https://phmuseum.com/grants/2020-women-photographers-grant',
  },
  {
    title: 'Photo Vogue Festival 2020 displays works by Spandita Malik',
    subtitle: 'November 20, 2020',
    img: 'img/Photo Vogue.jpg',
    url: 'https://photovoguefestival.vogue.it/en/video-exhibition/projections/finalists-or-phmuseum-2020-women-photographers-grant',
  },
  {
    title: '“Nā́rī” published in Musée Magazine Issue No. 24 ‘Identity’',
    subtitle: 'October 15, 2020',
    img: 'img/Musee.jpg',
    url: 'img/4 link.pdf',
  },
  {
    title: 'City artist’s work on gender violence selected by British Journal',
    subtitle: 'October 01, 2020',
    img: 'img/The Pioneer.jpg',
    url: 'https://www.dailypioneer.com/2020/state-editions/city-artist---s-work-on-gender-violence-selected-by-british-journal.html',
  },
  {
    title: 'India born artist features among 18 talents in global search for Ones to Watch',
    subtitle: 'September 30, 2020',
    img: 'img/Daily World.jpg',
    url: 'img/3 link.pdf',
  },
  {
    title: 'NY based Indian artist’s work on gender violence features in British Journal of Photography',
    subtitle: 'September 30, 2020',
    img: 'img/Hindustan Times.jpg',
    url: 'img/2 link.pdf',
  },
  {
    title: 'City girl, Spandita Malik’s work on gender violence selected by British Journal of Photography',
    subtitle: 'September 30, 2020',
    img: 'img/Indian Express.jpg',
    url: 'img/1 link.pdf',
  },
  {
    title: 'Spandita’s work displayed at Ford Foundation Live Gallery, New York',
    subtitle: 'September 01, 2020',
    img: 'img/NYLive Arts.jpg',
    url: 'https://www.nylivearts-inbetween.org/spanditamalik',
  },
  {
    title: '“Nā́rī” selected as a part of ‘Vantage Point Sharjah 8’ atSharjah Art Foundation, Sharjah, United Arab Emirates',
    subtitle: 'August 29, 2020',
    img: 'img/Sharjah.jpg',
    url: 'http://sharjahart.org/sharjah-art-foundation/exhibitions/vantage-point-sharjah-8',
  },
  {
    title: 'Poloumi Basu in conversation with Firecracker Grant winner Spandita Malik',
    subtitle: 'August 23, 2020',
    img: 'img/Poloumi Basu.jpg',
    url: 'https://www.instagram.com/tv/CEJdY_UnTP6/?igshid=1pisijh29377d',
  },
  {
    title: '“2020 Gender Justice and the Arts: Radical Imagination” at Sakhi for South Asian Women Online Show',
    subtitle: 'August 22, 2020',
    img: 'img/Sakhi.jpg',
    url: 'https://sakhi.org/genderjusticeandthearts/gallery/',
  },
  {
    title: '“Ones to Watch 2020: Spandita Malik”, British Journal of Photography',
    subtitle: 'August 01, 2020',
    img: 'img/BJP.jpg',
    url: 'https://www.1854.photography/2020/09/spandita-malik-i-was-never-happy-with-a-photograph-being-a-photograph/',
  },
  {
    title: '2021 Artist-in-Residence at The Center for Photography, Woodstock, NY',
    subtitle: 'August 01, 2020',
    img: 'img/CPW.jpg',
    url: 'https://www.cpw.org/resources/artist-residencies/',
  },
  {
    title: 'Spandita Malik selected for Feminist Incubator Residency at Project for Empty Spaces, Newark, NJ',
    subtitle: 'July 01, 2020',
    img: 'img/PES.jpg',
    url: 'https://www.projectforemptyspace.org/spandita-malik',
  },
  {
    title: 'SUNDAY GUARDIAN LIVE “NĀ́RĪ EMBODIES WOMEN AND THEIR NARRATIVES”',
    subtitle: 'May 09, 2020',
    img: 'img/news/news10.jpg',
    url: 'https://www.sundayguardianlive.com/culture/nari-embodies-women-narratives',
  },
  {
    title: 'AAJ SAMAJ FEATURES SPANDITA MALIK\'S WORK NĀ́RĪ',
    subtitle: 'May 07, 2020',
    img: 'img/news/news9.jpg',
    url: 'https://www.aajsamaaj.com/khas-khabar/seeing-womens-oppression-from-an-independent-perspective/',
  },
  {
    title: 'ANNOUNCING SPANDITA MALIK AS ONE OF THE NINE WINNERS OF 2020 FIRECRACKER PHOTOGRAPHIC GRANT',
    subtitle: 'May 11, 2020',
    img: 'img/news/news11.jpg',
    url: 'https://www.fire-cracker.org/grant/#grant',
  },
  {
    title: 'VISUAL ART CENTER OF NEW JERSEY: CABIN FEVER ARTIST TALK BY SPANDITA MALIK',
    subtitle: 'May 26, 2020',
    img: 'img/news/news12.jpg',
    url: 'https://www.artcenternj.org/programs/cabin-fever-artist-talks/cabin-fever-artist-talk-spandita-malik/',
  },
  {
    title: 'DAINIK BHASKAR: CITY LIFE INTERVIEWS SPANDITA ON HER PROJECT ‘NĀRĪ’',
    subtitle: 'April 30, 2020',
    img: 'img/news/news8.jpg',
    url: 'https://spandita-malik.com/img/news13.jpg',
  },
  {
    title: 'PICTER FEATURES “NĀ́RĪ” IN THEIR BLOG POST BY GABRIEL SHALOM',
    subtitle: 'April 07, 2020',
    img: 'img/news/news7.jpg',
    url: 'https://blog.picter.com/parsons-ahead-of-the-remote-education-curve/',
  },
  {
    title: 'Harper\'s Magazine publishes "Kosar" by Spandita Malik in April 2020 Issue',
    subtitle: 'April 01, 2020',
    img: 'img/news/news6.jpeg',
    url: 'https://harpers.org/archive/2020/04/',
  },
  {
    title: 'Musée Magazine Features, Women\'s History Month: Spandita Malik',
    subtitle: 'March 12, 2020',
    img: 'img/news/news5.png',
    url: 'https://museemagazine.com/culture/2020/3/17/woman-crush-wednesday-spandita-malik',
  },
  {
    title: 'Spandita Malik Announced as a Participants in the 2020 Immigrant Artist Mentoring Program: Visual and Multidisciplinary Arts',
    subtitle: 'February 03, 2020',
    img: 'img/news/news1.png',
    url: 'https://current.nyfa.org/post/190627696648/announcing-the-participants-in-the-2020-immigrant',
  },
  {
    title: 'Spandita Malik Selected as a Baxter St CCNY 2020 Workspace Resident',
    subtitle: 'December 19, 2019',
    img: 'img/news/news2.jpeg',
    url: 'https://www.baxterst.org/spandita-malik/',

  },
  {
    title: 'MFA PHOTOGRAPHY GRAD, SPANDITA MALIK, REPRESENTED BY PARSONS FACULTY & ART ADVISOR SARAH HASTED THE SCHOOL OF ART, MEDIA AND TECHNOLOGY AT PARSONS',
    subtitle: 'October 17, 2019',
    img: 'img/news/news4.png',
    url: 'https://mailchi.mp/b8145bca4d74/fzfuzr8y1o-926889?e=0c8cf93607',

  },
  {
    title: 'SARAH HASTED INTRODUCES THE NEXT GENERATION ARTIST: SPANDITA MALIK',
    subtitle: 'October 08, 2019',
    img: 'img/news/news3.png',
    url: 'https://myemail.constantcontact.com/SARAH-HASTED-INTRODUCES-THE-NEXT-GENERATION--Artist-SPANDITA-MALIK.html?soid=1125546858147&aid=8xnpi03dNjk',

  },
].sort((a, b) => toDate(b.subtitle) - toDate(a.subtitle)).map(New);

const News = () => {
  const DomElement = html`
    <div class="news">
    ${Overlay(projects)}
    ${Nav(eventManager)}
      <div class="grid-news">
        ${news}
      </div>
    ${Footer()}
    </div>
  `;

  return DomElement;
};

module.exports = News();
