module.exports = [
  [
    'img/images/2 Commercial/1.jpg',
    'img/images/2 Commercial/2.jpg',
    'img/images/2 Commercial/3.jpg',
    'img/images/2 Commercial/4.jpg',

    'img/images/2 Commercial/5.jpg',
    'img/images/2 Commercial/6.jpg',
    'img/images/2 Commercial/7.jpg',
    'img/images/2 Commercial/8.jpg',
  ],
  [
    'img/images/2 Commercial/9.jpg',
    'img/images/2 Commercial/10.jpg',
    'img/images/2 Commercial/11.jpg',
    'img/images/2 Commercial/12.jpg',

    'img/images/2 Commercial/13.jpg',
    'img/images/2 Commercial/14.jpg',
    'img/images/2 Commercial/15.jpg',
    'img/images/2 Commercial/16.jpg',
  ],
  [
    'img/images/2 Commercial/17.jpg',
    'img/images/2 Commercial/18.jpg',
    'img/images/2 Commercial/19.jpg',
    'img/images/2 Commercial/20.jpg',

    'img/images/2 Commercial/21.jpg',
    'img/images/2 Commercial/22.jpg',
    'img/images/2 Commercial/23.jpg',
    'img/images/2 Commercial/24.jpg',
  ],
  [
    'img/images/2 Commercial/25.jpg',
    'img/images/2 Commercial/26.jpg',
    'img/images/2 Commercial/27.jpg',
    'img/images/2 Commercial/28.jpg',

    'img/images/2 Commercial/29.jpg',
    'img/images/2 Commercial/30.jpg',
    'img/images/2 Commercial/31.jpg',
    'img/images/2 Commercial/32.jpg',
  ],
  [
    'img/images/2 Commercial/33.jpg',
    'img/images/2 Commercial/34.jpg',
    'img/images/2 Commercial/35.jpg',
    'img/images/2 Commercial/36.jpg',

    'img/images/2 Commercial/37.jpg',
    'img/images/2 Commercial/38.jpg',
    'img/images/2 Commercial/39.jpg',
    'img/images/2 Commercial/40.jpg',
  ],
  [
    'img/images/2 Commercial/41.jpg',
    'img/images/2 Commercial/42.jpg',
    'img/images/2 Commercial/43.jpg',
    'img/images/2 Commercial/44.jpg',

    'img/images/2 Commercial/45.jpg',
    'img/images/2 Commercial/46.jpg',
    'img/images/2 Commercial/47.jpg',
    'img/images/2 Commercial/48.jpg',
  ],
  [
    'img/images/2 Commercial/49.jpg',
    'img/images/2 Commercial/50.jpg',
    'img/images/2 Commercial/51.jpg',
    'img/images/2 Commercial/52.jpg',

    'img/images/2 Commercial/53.jpg',
    'img/images/2 Commercial/54.jpg',
    'img/images/2 Commercial/55.jpg',
    'img/images/2 Commercial/56.jpg',
  ],

];
