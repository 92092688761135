const { html, $$, $ } = require('@forgjs/noframework');
const link = require('../components/link');
const eventManager = require('./eventManager');

const playerLink = (title, iProject) => {
  const DomElement = link(`/projects/${iProject}`, html`<li>${title}</li>`);

  DomElement.addEventListener('click', () => {
    if (window.screen.width > 640) {
      eventManager.emit('player-mode', iProject);
    } else {
      eventManager.emit('grid-mode', iProject);
    }

    eventManager.emit('open-overlay');
  });

  DomElement.addEventListener('mouseenter', () => {
    eventManager.emit('project-hover', iProject);
  });

  return DomElement;
};

const Overlay = (projects) => {
  const newsLink = link('/news', html`<h1>News</h1>`, eventManager, 'open-overlay');
  const aboutMeLink = link('/about-me', html`<h1>About me</h1>`, eventManager, 'open-overlay');
  const cvLink = link('/cv', html`<h1>CV</h1>`, eventManager, 'open-overlay');
  const installsLink = link('/install', html`<h1>Install</h1>`, eventManager, 'open-overlay');

  const DomElement = html`
    <div class="home__overlay">
      <div class="home__overlay__left">
        <div class="home__overlay__line"></div>
        <h1 style="opacity:1">Projects</h1>
        <ul class="home__overlay__projects">
          ${projects.map(({ title }, i) => playerLink(title, i))}
        </ul>
        ${installsLink}
        ${newsLink}
        ${aboutMeLink}
        ${cvLink}
      </div>
      <div class="home__overlay__right">
        ${projects.map((project, i) => html`<img src="${project.images[0].src}" class="home__overlay__image ${i === 0 ? 'visible' : ''}">`)}
        <img src="/img/news/news26.jpg" class="home__overlay__image">
      </div>
    </div>
  `;

  aboutMeLink.addEventListener('mouseenter', () => {
    eventManager.emit('project-hover');
  });

  cvLink.addEventListener('mouseenter', () => {
    eventManager.emit('project-hover');
  });

  newsLink.addEventListener('mouseenter', () => {
    eventManager.emit('project-hover', projects.length);
  });

  newsLink.addEventListener('mouseleave', () => {
    if ($('.home__overlay__image.visible')) {
      $('.home__overlay__image.visible').classList.remove('visible');
    }
  });

  eventManager.subscribe('project-hover', (index) => {
    if ($('.home__overlay__image.visible')) {
      $('.home__overlay__image.visible').classList.remove('visible');
    } if ($$('.home__overlay__image')[index]) {
      $$('.home__overlay__image')[index].classList.add('visible');
    }
  });

  eventManager.subscribe('open-overlay', () => {
    DomElement.classList.toggle('open');
  });

  return DomElement;
};

module.exports = Overlay;
