const { html } = require('@forgjs/noframework');
const Nav = require('../components/Nav2');

const Video = (id) => html`<div style="padding:56.25% 0 0 0;position:relative;width:80%; margin:auto">
<iframe src="https://player.vimeo.com/video/${id}?color=ffffff&portrait=0"
style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" 
allow="autoplay; fullscreen" allowfullscreen></iframe>
</div>`;

const Videos = () => {
  const DomElement = html`
    <div class="videos">
      ${Nav()}
      ${['362415225', '362418925', '529607945', '362415901'].map(Video)}
      
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  `;

  return DomElement;
};

module.exports = Videos();
