module.exports = [
  [
    {
      title: "Spandita Malik: Nā́rī, 2022",
      medium: "Blue Sky Gallery,  Oregon Center for the Photographic Arts, Portland, OR ",
      src: 'img/images/1 Projects/expo/expo2.jpg',
    },
    {
      title: "Spandita Malik: Nā́rī, 2022",
      medium: "Blue Sky Gallery,  Oregon Center for the Photographic Arts, Portland, OR ",
      src: 'img/images/1 Projects/expo/expo1.jpg',
    },
    {
      title: "Spandita Malik: Nā́rī, 2022",
      medium: "Blue Sky Gallery,  Oregon Center for the Photographic Arts, Portland, OR ",
      src: 'img/images/1 Projects/expo/expo6.jpg',
    },
    {
      title: "Say the dream was real and the wall imaginary, 2022",
      medium: "ane Lombard Gallery, New York, NY",
      src: 'img/images/1 Projects/expo/expo5.jpeg',
    },
  ],
  [
    {
      title: "Vadhu: The Embroidered Bride, 2022",
      medium: "Baxter St. CCNY, New York, NY",
      src: 'img/images/1 Projects/expo/expo9.jpg',
    },
    {
      title: "Vadhu: The Embroidered Bride, 2022",
      medium: "Baxter St. CCNY, New York, NY",
      src: 'img/images/1 Projects/expo/expo8.jpg',
    },
    {
      title: "BeautifulWorld(s): KCAI Biennial, 2021",
      medium: "H&R Block Artspace, Kansas City, MO",
      src: 'img/images/1 Projects/expo/expo4.jpg',
    },
    {
      title: "What We Become When We Are Unbound., 2021",
      medium: "Project for Empty Spaces, Newark, NJ",
      src: 'img/images/1 Projects/expo/expo7.jpg',
    },
  ],
  [
    {
      title:"Spandita Malik: Threads of Identity, 2021",
      medium:"Visual Art Center of New Jersey, Summit, NJ",
      src: 'img/images/1 Projects/expo/expo3.jpg',
    },
    {
      title:"Spandita Malik: Threads of Identity, 2021",
      medium:"Visual Art Center of New Jersey, Summit, NJ",
      src: 'img/images/1 Projects/expo/expo11.jpg',
    },
    {
      title:"Spandita Malik: Threads of Identity, 2021",
      medium:"Visual Art Center of New Jersey, Summit, NJ",
      src: 'img/images/1 Projects/expo/expo10.jpg',
    },
  ],
  [
    {
      src: 'img/images/1 Projects/expo/1.jpg'
    },
    {
      src: 'img/images/1 Projects/expo/2.jpg'
    },
    {
      src: 'img/images/1 Projects/expo/3.jpg'
    },
    {
      src: 'img/images/1 Projects/expo/4.jpg'
    },
  ],
  [
    {
      src: 'img/images/1 Projects/expo/5.jpg'
    },
    {
      src: 'img/images/1 Projects/expo/6.jpg'
    },
    {
      src: 'img/images/1 Projects/expo/7.jpg'
    },
    {
      src: 'img/images/1 Projects/expo/8.jpg'
    },
  ],
  [
    {
      src: 'img/images/1 Projects/expo/9.jpg'
    },
    {
      src: 'img/images/1 Projects/expo/1.jpg'
    },
  ],
];
