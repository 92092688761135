module.exports = [
  {
    title: "Jāḷī—Meshes of Resistance",

    description: `In Sanskrit and Indic languages derived from it, jāḷī  means structures like a net or mesh, fabrics created from intertwined elements that can be used to gather things together or allow partial glimpses of whatever lies behind it. This type of interwoven network possesses a tensile strength far greater than that of any of its constituent parts. Jāḷī can also indicate a type of openwork embroidery.
  I had been working with women in India for five years. The community had grown stronger and I realized that more women reached out to work and share their stories with me in the field through word of mouth.  I believe that the enlargement of the community in this manner, embodies the resistance, strength and care that can be found in the community. Most women share stories of abuse and injustices. I had the privilege to be the bearer of the stories these women shared with me, to hear them, to question them, to understand the silences, the pauses, and to have the responsibility to retell, share, and pass on these stories. I collaborate with a community of 48 women in this project, enabling the women I photograph to shape their representations into self-images, joining their networks of support, extending their reach far beyond the circumscribed worlds the women are forced to inhabit. 
  These women choose to no longer exist entirely obscured to the gaze of strangers but to cast a wider net, to join together with each other, with me, and with the viewer, to enmesh themselves in a stronger fabric of resistance, one stitch at a time.`,

    images: [
      {
        title: "Jyoti",

        w: 63.5,
        h: 47,
        description:
          "Photographic transfer print on khaddar fabric, phulkari silk thread embroidery, beadwork",
        y: 2023,
        src: "img/promoted/project3/1.jpg",
      },

      {
        title: "Arifa Bano",

        w: 63.5,
        h: 46.5,
        description:
          "Photographic transfer print on khadi  fabric, zardozi and gota-patti embroidery, beadwork, mirror work",
        y: 2023,
        src: "img/promoted/project3/2.jpg",
      },

      {
        title: "Charanjit",

        w: 50,
        h: 66,
        description:
          "Photographic transfer print on khaddar fabric, phulkari silk thread embroidery",
        y: 2023,
        src: "img/promoted/project3/3.jpg",
      },

      {
        title: "Noshad Bee",

        w: 64.5,
        h: 47.5,
        description:
          "Photographic transfer print on khadi  fabric, zardozi and gota-patti embroidery, beadwork",
        y: 2023,
        src: "img/promoted/project3/4.jpg",
      },

      {
        title: "Shabeena Bhegam",

        w: 55.5,
        h: 42,
        description:
          "Photographic transfer print on khadi  fabric, zardozi and gota-patti embroidery, beadwork",
        y: 2023,
        src: "img/promoted/project3/5.jpg",
      },

      {
        title: "Parween Devi III",

        w: 40.25,
        h: 53.5,
        description:
          "Photographic transfer print on khaddar fabric, phulkari silk thread embroidery",
        y: 2023,
        src: "img/promoted/project3/6.jpg",
      },

      {
        title: "Meena III",

        w: 26,
        h: 22,
        description:
          "Photographic transfer print on khaddar fabric, phulkari silk thread embroidery",
        y: 2024,
        src: "img/promoted/project3/7.jpg",
      },

      {
        title: "Farhana",

        w: 34.5,
        h: 45.5,
        description:
          "Photographic transfer print on khadi  fabric, zardozi and gota-patti embroidery, beadwork",
        y: 2023,
        src: "img/promoted/project3/8.jpg",
      },

      {
        title: "Shameena Solanki",

        w: 30,
        h: 38,
        description:
          "Photographic transfer print on khadi  fabric, zardozi and gota-patti embroidery, beadwork",
        y: 2024,
        src: "img/promoted/project3/9.jpg",
      },

      {
        title: "Pooja",

        w: 44.5,
        h: 32.5,
        description:
          "Photographic transfer print on khaddar fabric, phulkari silk thread embroidery",
        y: 2023,
        src: "img/promoted/project3/10.jpg",
      },

      {
        title: "Ranjeet Kaur",

        w: 36.5,
        h: 27,
        description:
          "Photographic transfer print on khaddar fabric, phulkari silk thread embroidery",
        y: 2023,
        src: "img/promoted/project3/11.jpg",
      },
    ],
  },
  {
    title: "Nā́rī (2019-Ongoing)",
    description: `“There has always been a sense of legacy being passed among women through the language of embroidery and handcraft, inherited by generations of women and passed along, to break the oppressor by simple but significant hand movements captured on fabric, written in thread.”
  In Sanskrit, nā́rī means woman, wife, female, or an object regarded as feminine but can also mean sacrifice. While misogyny is hardly exclusive to one country, India bears ghastly symptoms of it. While searching for women in India in self-help groups who are learning to embroider, I heard about women who weren’t allowed to come to the centers. They are either not allowed to leave the house, due to their husbands or fathers, or they felt unsafe leaving the house. I traveled to Lucknow, Jaipur, and Chamkaur Sahib where I photographed and interviewed these women about their harsh economic and social realities. Some women talk about their domestic violence. 
  There was a conscious effort in stepping away from the colonized eye in documentary photography of India. As a photographer, I wanted to share the power of representation with the subject. I meet these women, taking their photographs in a place and manner of their choosing. I then transfer the images to local homespun muslin, a material that in this context carries echoes of Gandhi's khadi, the cloth he encouraged Indians to weave in protest of forced dependence on British industrially produced fabric. I send the portraits back to the women pictured, who then embellish their likenesses with embroidery, according to their own ideas about how they wish to be seen. These artistic collaborations subverted the idea of the artist as the main producer by giving each woman her own creative entity within her own craft. It also engaged the problem of representation in portrait photography as addressed by giving women control over their own image. These collaborations created a connection between the women and I in our shared language of art; by listening through their inherited language of embroidery, I learnt the true meaning of nā́rī.`,
    images: [
      {
        title: "Kosar",
        w: 26,
        h: 35,
        description:
          "Photographic transfer print on khadi, gotta-patti and zardozi embroidery",
        y: 2019,
        src: "/img/promoted/project1/1.jpg",
      },

      {
        title: "Fozia",
        w: 23,
        h: 19,
        description: "Photographic transfer print on khadi, embroidery",
        y: 2019,
        src: "/img/promoted/project1/2.jpg",
      },

      {
        title: "Nuzrat Praween",
        w: 36.5,
        h: 43,
        description:
          "Photographic transfer print on voile fabric, chikankari embroidery",
        y: 2019,
        src: "/img/promoted/project1/3.jpg",
      },

      {
        title: "Rajni Mehra",
        w: 19.5,
        h: 34,
        description:
          "Photographic transfer print on khaddar fabric, phulkari silk thread embroidery",
        y: 2019,
        src: "/img/promoted/project1/4.jpg",
      },

      {
        title: "Lakhbir Kaur",
        w: 39,
        h: 41,
        description:
          "Photographic transfer print on khaddar fabric, phulkari silk thread embroidery",
        y: 2019,
        src: "/img/promoted/project1/5.jpg",
      },

      {
        title: "Rukmesh Kumari",
        w: 40,
        h: 49,
        description:
          "Photographic transfer print on khaddar, phulkari silk thread embroidery",
        y: 2020,
        src: "/img/promoted/project1/6.jpg",
      },

      {
        title: "Sarfaraz",
        w: 54.5,
        h: 32.5,
        description:
          "Photographic transfer print on khadi, zardozi embroidery, appliqué",
        y: 2020,
        src: "/img/promoted/project1/7.jpg",
      },

      {
        title: "Parween Devi",
        w: 43.5,
        h: 53,
        description:
          "Photographic transfer print on khaddar, phulkari silk thread embroidery",
        y: 2020,
        src: "/img/promoted/project1/8.jpg",
      },

      {
        title: "Aayesha",
        w: 38,
        h: 46.5,
        description:
          "Photographic transfer print on khadi, embroidery, crochet",
        y: 2020,
        src: "/img/promoted/project1/9.jpg",
      },

      {
        title: "Sabaa Qureshi",
        w: 39,
        h: 55.5,
        description:
          "Photographic transfer print on khadi, zardozi embroidery, appliqué",
        y: 2020,
        src: "/img/promoted/project1/10.jpg",
      },
      {
        title:"Renu",
        w:36.5,
        h:54,
        description:"Photographic transfer print on khadi fabric, gotta patti and zardozi embroidery",
        y:2020,
        src: "/img/promoted/project1/11.jpg",
      }
    ],
  },
  {
    title: "Vadhu (2021- Ongoing)",
    description: `During the pandemic, the community of women whom I'd worked with, came together to support and collaborate to create ‘Vadhu’. While we had talked a lot about change, the kind that was inevitable and the kind of hopeful daydreams, the past and the present had existed and erupted on occasions simultaneously. The women met in their backyards, bringing with them photo albums, mostly wedding photographs and the conversation started; they talked of themselves, the self they saw in the photographs, a narrative of someone transformed as we walked through the memory lanes of many decades. Memories that existed in the photograph, skipped timelines, often jumped, sometimes ground to halt on different occasions informed a new shape of the memory. 
We collaborated through international mail and phone calls, but more importantly during the process of creation, we could build a community to listen to each other in a different but significant manner, whatever deemed necessary to sustain the community. The women embroider the portraits of their wedding or in some cases the only photograph of themselves; embroidering the past, recalling, recollecting, remembering and reclaiming the narrative of the portrait in sync with the present; the language of embroidery reshaped the memory of the photograph. `,

    images: [
      {
        title: "Radha",
        w: 28.5,
        h: 40,
        description:
          "Photographic transfer print on khaddar fabric, phulkari silk thread embroidery",
        y: 2021,
        src: "/img/promoted/project2/1.jpg",
      },

      {
        title: "Sarfaraz II",
        w: 26,
        h: 38.5,
        description:
          "Photographic transfer print on khadi fabric, zardozi embroidery, beadwork",
        y: 2021,
        src: "/img/promoted/project2/2.jpg",
      },

      {
        title: "Rajni Mehra II",

        w: 25,
        h: 31.5,
        description:
          "Photographic transfer print on khaddar fabric, phulkari silk thread embroidery",
        y: 2021,
        src: "/img/promoted/project2/3.jpg",
      },

      {
        title: "Rukmesh Kumari II",

        w: 27,
        h: 40,
        description:
          "Photographic transfer print on khaddar fabric, phulkari silk thread embroidery",
        y: 2021,
        src: "/img/promoted/project2/4.jpg",
      },

      {
        title: "Fozia II",

        w: 20.5,
        h: 29.5,
        description:
          "Photographic transfer print on khadi fabric, embroidery, crochet",
        y: 2021,
        src: "/img/promoted/project2/5.jpg",
      },

      {
        title: "Sindhu II",

        w: 40,
        h: 49,
        description:
          "Photographic transfer print on khadi fabric, zardozi embroidery, beadwork, coin embroidery",
        y: 2021,
        src: "/img/promoted/project2/6.jpg",
      },

      {
        title: "Radha Rani II",

        w: 26,
        h: 49,
        description:
          "Photographic transfer print on khaddar fabric, phulkari silk thread embroidery",
        y: 2021,
        src: "/img/promoted/project2/7.jpg",
      },

      {
        title: "Meena",

        w: 28,
        h: 39,
        description:
          "Photographic transfer print on khaddar fabric, phulkari silk thread embroidery",
        y: 2022,
        src: "/img/promoted/project2/8.jpg",
      },

      {
        title: "Praween Devi III",

        w: 28,
        h: 39,
        description:
          "Photographic transfer print on khaddar fabric, phulkari silk thread embroidery",
        y: 2022,
        src: "/img/promoted/project2/9.jpg",
      },

      {
        title: "Rukmesh Kumari III",

        w: 28,
        h: 38.5,
        description:
          "Photographic transfer print on khaddar fabric, phulkari silk thread embroidery",
        y: 2022,
        src: "/img/promoted/project2/10.jpg",
      },
    ],
  },
];
