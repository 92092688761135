const { html } = require('@forgjs/noframework');
const eventManager = require('./eventManager');

const gridImage = (params, gridIndex, imageIndex) => {
  const src = params.src || params;
  const title = params.title || '';
  const medium = params.medium || '';
  const size = params.size || '';


  const DomElement = html`<div class="grid-commercial__cell">
    <img class="grid-commercial__img" src="${src}" loading="lazy">
    <div class="info">
      <h2>${title}</h2>
      <p>${medium}</p>
      <p>${size}</p>
    </div>
  </div>`;

  DomElement.addEventListener('click', () => {
    console.log(gridIndex, imageIndex);
    eventManager.emit('player-mode', gridIndex, imageIndex);
  });
  return DomElement;
};

const Grid = (images, gridIndex, className) => {
  const DomElement = html`<div class="${className ? className : 'grid-commercial'}">
    ${images.map((src, imageIndex) => gridImage(src, gridIndex, imageIndex))}
  </div>`;

  DomElement.addEventListener('contextmenu', (e) => {
    e.preventDefault();
    e.stopPropagation();
  });

  return DomElement;
};

module.exports = Grid;
