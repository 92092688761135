const { html } = require('@forgjs/noframework');
const routerComponent = require('./routerComponent');
const Rooter = require('./rooterEmiter');

const App = html`<div id="app">
  ${routerComponent}
</div>`;

Rooter.emit('reroot', document.location.pathname);

document.body.appendChild(App);
