const EnterHome = require('./Enter')('/news');
const EnterCommercial = require('./Enter')('/commercials', '../img/landingCommercial.jpg');
const Home = require('./Home');
const p404 = require('./Page404');
const AboutMe = require('./AboutMe');
const rooterEmiter = require('./rooterEmiter');
const Cv = require('./CV');
const Commercial = require('./Commercial');
const Protfolio = require('./Commercial/portfolio');
const Installs = require('./Commercial/Expositions');
const Videos = require('./Videos');
const CvHome = require('./Home/Cv');
const AboutMeHome = require('./Home/AboutMe');
const News = require('./Home/News');
const eventManager = require('./Home/eventManager');
const Promoted = require('./Promoted');


const routes = {
  // index
  '/': EnterHome,
  '/404': p404,
  '/about-me': AboutMeHome,
  '/cv': CvHome,
  '/projects': Home,
  '/news': News,
  '/install': Installs,
  '/portfolio':Promoted(),
  // commercial
  '/commercial': EnterCommercial,
  '/commercials': Commercial,
  '/commercial/cv': Cv(),
  '/commercial/about-me': AboutMe(),
  '/commercial/videos': Videos,

  '/project1': Promoted(0),
  '/project2': Promoted(1),
  '/project3': Promoted(2),
};

rooterEmiter.subscribe('reroot', (url) => {
  let path = url;
  let projectId = -1;
  if(path.startsWith('/projects')){
    path = '/projects';
    projectId = parseInt(url.split('/')[2]);
  }
  let rUrl = routes[path] ? path : '/404';
  if (!rooterEmiter.current) {
    rooterEmiter.current = routes[rUrl];
    EnterHome.replaceWith(routes[rUrl]);
    rooterEmiter.emit('rerooted', rUrl, routes[rUrl]);
  } else if (rooterEmiter.current !== routes[rUrl]) {
    rooterEmiter.current.replaceWith(routes[rUrl]);
    rooterEmiter.current = routes[rUrl];
    document.body.scrollTop = 0;
    rooterEmiter.emit('rerooted', rUrl, routes[rUrl]);
  }
  if(projectId >= 0){
    eventManager.emit('player-mode', projectId, 0);
    window.history.replaceState({}, "", url);
  } else {
    window.history.replaceState({}, "", rUrl);
  }
});

module.exports = EnterHome;
