const { html } = require('@forgjs/noframework');
const Nav = require('../components/Nav');
const Player = require('./Player');
const Grid = require('./Grid');
const Footer = require('../components/Footer');
const EventManager = require('./eventManager');
const Description = require('./Description');
const Projects = require('../projects');
const Overlay = require('./Overlay');
const Links = require('../components/links');
const RouterEmitter = require('../rooterEmiter');
const { preloadImage } = require('../utils');

const Home = () => {
  const DomElement = html`
  <div>
    ${Nav(EventManager)}
    ${Overlay(Projects, EventManager)}
    <div class="home__container">
        ${Description(Projects, EventManager)}
        ${Player(Projects, EventManager)}
        ${Grid(Projects, EventManager)}
    </div>
    ${Footer()}
    ${Links()}
  </div>`;

  RouterEmitter.subscribe('rerooted', (url) => {
    if (url !== '/projects') return;
    Projects[0].images.map((i) => preloadImage(i.src));
    if (window.screen.width > 640) return;
    EventManager.emit('grid-mode', 0);
  });

  return DomElement;
};

module.exports = Home();
