const { html, $ } = require('@forgjs/noframework');
const EventManager = require('./eventManager');
const {
  textTitle,
  moreInfoText,
  textSize,
  mediumText,
} = require('../utils');

const Description = (projects) => {
  const DomElement = html`<div class="description">
    <div class="description__content">
      <h1 class="description__title">${textTitle(projects[0], 0)}</h1>
      <p class="description__text">${projects[0].description}</p>
      <div class="description__info">
        <h3 class="description__info__title">${projects[0].images[0].title}</h3>
        <p  class="description__info__moreInfo">${moreInfoText(projects[0].images[0])}</p>
        <p  class="description__info__size">${textSize(projects[0].images[0])}</p>
        <p  class="description__info__medium">${mediumText(projects[0].images[0])}</p>
      </div>
    </div>
  </div>`;

  const updateText = (pIndex, iIndex = 0) => {
    $('.description__title', DomElement).innerText = textTitle(projects[pIndex], iIndex);
    $('.description__text', DomElement).innerText = projects[pIndex].description;

    $('.description__info__title', DomElement).innerText = projects[pIndex].images[iIndex].title || '';
    $('.description__info__size', DomElement).innerText = textSize(projects[pIndex].images[iIndex]);
    $('.description__info__medium', DomElement).innerText = mediumText(projects[pIndex].images[iIndex]);
    $('.description__info__moreInfo', DomElement).innerText = moreInfoText(projects[pIndex].images[iIndex]);
    $('.description__info', DomElement).classList.remove('hidden');
  };

  EventManager.subscribe('image-change', updateText);

  EventManager.subscribe('player-mode', updateText);

  EventManager.subscribe('grid-mode', (pIndex) => {
    $('.description__title', DomElement).innerText = projects[pIndex].title;
    $('.description__info', DomElement).classList.add('hidden');
  });

  return DomElement;
};

module.exports = Description;
