const { html, $ } = require('@forgjs/noframework');
const Icon = require('../components/Icon');
const { preloadImage } = require('../utils');

const Player = (projects, eventManager) => {
  let pIndex = 0;
  let iIndex = 0;
  const leftIcon = Icon('chevron-left');
  const rightIcon = Icon('chevron-right');


  const DomElement = html`
  <div class="player">
    <div class="player__loader">${Icon('loader')}</div>
    ${leftIcon}
    <img src="${projects[0].images[0].src}" class="player__image">
    ${rightIcon}
    <div class="player__nav">
      <div class="player__gridView">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>`;

  const loader = $('.player__loader', DomElement);

  rightIcon.addEventListener('click', (e) => {
    e.stopPropagation();
    eventManager.emit('player-next');
  });
  $('.player__gridView', DomElement).addEventListener('click', (e) => {
    eventManager.emit('grid-mode', pIndex);
    e.stopPropagation();
  });

  leftIcon.addEventListener('click', (e) => {
    e.stopPropagation();
    eventManager.emit('player-prev');
  });

  // evt managment

  eventManager.subscribe('player-mode', (projectIndex = 0, imageIndex = 0) => {
    DomElement.style.display = null;
    if (iIndex !== imageIndex) {
      eventManager.emit('image-change', projectIndex, imageIndex);
    }
    pIndex = projectIndex;
    iIndex = imageIndex;
    $('.player__image', DomElement).setAttribute('src', projects[projectIndex].images[imageIndex].src);
  });

  eventManager.subscribe('player-next', () => {
    if (projects[pIndex] && projects[pIndex].images[iIndex + 1]) {
      iIndex += 1;
      eventManager.emit('image-change', pIndex, iIndex);
    } else if (projects[pIndex + 1]) {
      iIndex = 0;
      pIndex += 1;
      eventManager.emit('image-change', pIndex, iIndex);
      eventManager.emit('project-change', pIndex, iIndex);
    } else {
      pIndex = 0;
      iIndex = 0;
      eventManager.emit('image-change', pIndex, iIndex);
      eventManager.emit('project-change', pIndex, iIndex);
    }


    $('.player__image', DomElement)
      .setAttribute('src', projects[pIndex].images[iIndex].src);
  });

  eventManager.subscribe('player-prev', () => {
    if (projects[pIndex] && projects[pIndex].images[iIndex - 1]) {
      iIndex -= 1;
      eventManager.emit('image-change', pIndex, iIndex);
    } else if (projects[pIndex - 1]) {
      pIndex -= 1;
      iIndex = projects[pIndex].images.length - 1;
      eventManager.emit('image-change', pIndex, iIndex);
      eventManager.emit('project-change', pIndex, iIndex);
    } else {
      pIndex = projects.length - 1;
      iIndex = projects[pIndex].images.length - 1;
      eventManager.emit('image-change', pIndex, iIndex);
      eventManager.emit('project-change', pIndex, iIndex);
    }


    $('.player__image', DomElement)
      .setAttribute('src', projects[pIndex].images[iIndex].src);
  });

  eventManager.subscribe('grid-mode', () => {
    DomElement.style.display = 'none';
  });

  eventManager.subscribe('project-change', (pI) => {
    window.history.replaceState({}, "", `/projects/${pI}`);
    projects[pI].images.map((i) => preloadImage(i.src));
  });

  eventManager.subscribe('image-change', () => {
    loader.classList.remove('hidden');
  });

  DomElement.addEventListener('contextmenu', (e) => {
    e.preventDefault();
    e.stopPropagation();
  });

  DomElement.addEventListener('click', (e) => {
    const { width } = DomElement.getBoundingClientRect();
    if (e.offsetX > width / 2) {
      eventManager.emit('player-next');
    } else {
      eventManager.emit('player-prev');
    }
  });

  $('.player__image', DomElement).addEventListener('load', () => {
    loader.classList.add('hidden');
  });

  document.addEventListener('keydown', (e) => {
    if (DomElement.style.display === 'none') return;
    if (e.key === 'ArrowRight') {
      eventManager.emit('player-next');
    } else if (e.key === 'ArrowLeft') {
      eventManager.emit('player-prev');
    }
  });

  return DomElement;
};

module.exports = Player;
