const { html, smoothScrollTo, $ } = require('@forgjs/noframework');
const Nav = require('../components/Nav2');
const Grid = require('./Grid');
const Player = require('./player');
const Links = require('../components/links');
const Footer = require('../components/Footer');
const eventManager = require('./eventManager');

const pictures = [
  [
    {
      src: '/img/Portfolio/1.jpg',
      title: 'Kosar',
      size: '35 x 26 inch',
      medium: 'Photographic Transfer Print on Khadi, Gotta Patti and Zardozi Embroidery',
    },
    {
      src: '/img/Portfolio/2.jpg',
      title: 'Fozia',
      size: '23 x 19 inch',
      medium: 'Photographic transfer Print on Khadi, Embroidery ',
    }, {
      src: '/img/Portfolio/3.jpg',
      title: 'Parween Devi',
      size: '43.5 x 53 inch ',
      medium: 'Photographic transfer Print on Khaddar, Phulkari silk thread Embroidery ',
    }, {
      src: '/img/Portfolio/4.jpg',
      title: 'Radha ',
      size: '28.5 x 40 inch',
      medium: 'Photographic transfer print on khaddar fabric, phulkari silk thread embroidery',
    }, {
      src: '/img/Portfolio/5.jpg',
      title: 'Rajni Mehra II',
      size: '25 x 31.5  inch',
      medium: 'Photographic transfer print on khaddar fabric, phulkari silk thread embroidery',
    }, {
      src: '/img/Portfolio/6.jpg',
      title: 'Nuzrat Praween ',
      size: '6.5 x 43 inch ',
      medium: 'Photographic transfer print on Voile Fabric, Chikankari embroidery ',
    },
  ],
  [
    {
      src: '/img/Portfolio/7.jpg',
      title: 'Sindhu ',
      size: '22.5 x 32 inch ',
      medium: 'Photographic transfer Print on Khadi, Embroidery, Appliqué, Beadwork ',
    },
    {
      src: '/img/Portfolio/8.jpg',
      title: 'Praween Devi II',
      size: '37.5 x30.5  inch',
      medium: 'Photographic transfer print on khaddar fabric, phulkari silk thread embroidery',
    }, {
      src: '/img/Portfolio/9.jpg',
      title: 'Radha Rani II',
      size: '26 x 49 inch',
      medium: 'Photographic transfer print on khaddar fabric, phulkari silk thread embroidery',
    }, {
      src: '/img/Portfolio/10.jpg',
      title: 'Rukmesh Kumari ',
      size: '40 x 49 inch ',
      medium: 'Photographic transfer Print on Khaddar, Phulkari silk thread Embroidery ',
    }, {
      src: '/img/Portfolio/11.jpg',
      title: 'Sarfaraz II',
      size: '26 x 38.5  inch',
      medium: 'Photographic transfer print on khadi fabric, zardozi embroidery, beadwork',
    }, {
      src: '/img/Portfolio/12.jpg',
      title: 'Sindhu II',
      size: '40 x 49  inch',
      medium: 'Photographic transfer print on khadi fabric, zardozi embroidery, beadwork, coin embroidery',
    },
  ],
  [
    {
      src: '/img/Portfolio/13.jpg',
      title: 'Radha Rani ',
      size: '41.5 x 44 inch ',
      medium: 'Photographic transfer print on Khaddar Fabric, Phulkari Silk thread embroidery',
    },
    {
      src: '/img/Portfolio/14.jpg',
      title: 'Sarfaraz ',
      size: '54.5 x 32.5 inch ',
      medium: 'Photographic transfer Print on Khadi, Zardozi Embroidery, Appliqué ',
    }, {
      src: '/img/Portfolio/15.jpg',
      title: 'Kirna Devi',
      size: '11.5 x 16 inch',
      medium: 'Photographic transfer print on Khaddar Fabric, Phulkari Silk thread embroidery ',
    }, {
      src: '/img/Portfolio/16.jpg',
      title: 'Lakhbir Kaur ',
      size: '39 x 41 inch ',
      medium: 'Photographic transfer print on Khaddar Fabric, Phulkari Silk thread embroidery',
    }, {
      src: '/img/Portfolio/17.jpg',
      title: 'Renu ',
      size: '36.5 x 54 inch',
      medium: 'Photographic transfer Print on Khadi, Gotta Patti and Zardozi Embroidery ',
    }, {
      src: '/img/Portfolio/18.jpg',
      title: 'Rajni Mehra ',
      size: '19.5 x 34 inch ',
      medium: 'Photographic transfer print on Khaddar Fabric, Phulkari Silk thread embroidery',
    },
  ],
  [
    {
      src: '/img/Portfolio/19.jpg',
      title: 'Rukmesh Kumari II',
      size: '27 x 40  inch',
      medium: 'Photographic transfer print on khaddar fabric, phulkari silk thread embroidery',
    },
    {
      src: '/img/Portfolio/20.jpg',
      title: 'Memories on Desk',
      size: '32 x 40 inch',
      medium: 'Photo-sensitive sun-printing, Phulkari silk thread embroidery on Khaddar fabric',
    }, {
      src: '/img/Portfolio/21.jpg',
      title: 'Untitled I',
      size: '30 x 25 inch',
      medium: 'Photo-sensitive sun-printing, Zardozi and Gotta-Patti on Khadi fabric',
    }
  ],
];

const Commercial = () => {
  const grids = pictures.map((images, i) => Grid(images, i, 'grid-portfolio'));
  const DomElement = html`
  <div class="comercial">
    ${Nav()}
    ${Player(pictures, eventManager)}
    ${grids}
    ${Footer()}
    ${Links()}
  </div>`;


  return DomElement;
};

module.exports = Commercial();
