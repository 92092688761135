const { html } = require('@forgjs/noframework');
const link = require('../components/link');


const Enter = (url, bg) => {
  const DomElement = html`
  <div class="enter">
      ${link(url, html`<div class="title">Spandita Malik</div>`)}
  </div>`;

  return DomElement;
};

module.exports = Enter;
