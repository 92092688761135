const { html } = require('@forgjs/noframework');

const New = ({
  title,
  subtitle,
  img,
  url,
}) => {
  const DomElement = html`<a class="new" href="${url}" target="_blank">
    <h1>${title}</h1>
    <p>${subtitle}</p>
    <img src="${img}">
  </a>`;

  return DomElement;
};

module.exports = New;
