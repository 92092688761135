const { html, $ } = require("@forgjs/noframework");
const promoted = require("./promoted");
const projects = require('../projects');
const eventManager = require("../Home/eventManager");
const Overlay = require("../Home/Overlay");
const Nav = require("../components/Nav");
const {
  moreInfoText,
  textSize,
  mediumText,
  year,
} = require('../utils');
const Player = require("../Commercial/player");
const evt = require('./eventManager');


const gridImage = (img, eventManager, _, i) => {
  const DomElement = html`
  <div class="grid__cell">
    <img class="grid__img" src="${img.src}" loading="lazy">
    <div class="grid__cell__info">
      <h3>${img.title}</h3>
      <p>${textSize(img)}</p>
      <p>${img.description}</p>
      <p>${img.y}</p>
    </div>
  </div>`;
  DomElement.addEventListener('click', () => {
    eventManager.emit('player-mode', 0, i);
  });
  return DomElement;
};



const Promoted = (projectId) => {
  const project = promoted[projectId];

  const DomElement = html`<div class="promoted">
    <h1>${project.title}</h1>
    <p>${project.description}</p>
    ${Player([project.images.map(e => e.src)], evt)}
    <div class="grid">
      <div class="row"></div>
      <div class="row"></div>
    </div>
  </div>`;

  const container = $('.grid', DomElement);

  const renderGrid = () => {
    project.images.forEach((img, i) => {
      container.children[i % 2].appendChild(
        gridImage(img, evt, projectId, i)
      );
    });
  };
  renderGrid();
  return DomElement;
};

const Promoted2 = (projectId) => {
  let r;
  if(projectId !== undefined){
    r =  Promoted(projectId)
  } else {
    r = promoted.map((_, i) => Promoted(i));
  }
  return html`<div>
    ${Nav(eventManager)} ${Overlay(projects, eventManager)}
    ${r}
  </div>`
}

module.exports = Promoted2;
