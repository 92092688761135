// eslint-disable-next-line no-useless-escape
const isValidEmail = (str) => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(str);

const pfx = ['webkit', 'moz', 'MS', 'o', ''];
function PrefixedEvent(element, type, callback) {
  let t = type;
  for (let p = 0; p < pfx.length; p += 1) {
    if (!pfx[p]) {
      t = type.toLowerCase();
    }
    element.addEventListener(pfx[p] + t, callback, false);
  }
}

const randomId = (x, y) => Array(x).fill().map(() => Math.random().toString(36).substring(2, y + 2)).join('-');

const between = (val, min, max) => Math.max(Math.min(val, max), min);

const getOffsetTop = (element) => {
  let el = element;
  let offsetTop = 0;
  while (el) {
    offsetTop += el.offsetTop;
    el = el.offsetParent;
  }
  return offsetTop;
};

// // To add in noframework js

/**
 *
 * @param {Object} obj props object
 * @return String
 */
const toProps = (obj) => Object.keys(obj).map((key) => `${key} = "${obj[key]}"`).join(' ');

const safe = (str) => {
  const temp = document.createElement('div');
  temp.textContent = str;
  return temp.innerHTML;
};

const textSize = (img) => (img.w ? `${img.h} x ${img.w}  inch` : '');
const textTitle = (project, iIndex) => `${project.title} - ${iIndex + 1}/${project.images.length}`;
const mediumText = (img) => (img.subtitle ? img.subtitle : '');
const moreInfoText = (img) => (img.moreInfo ? img.moreInfo : '');
const year = (img) => img.y ? img.y : '';

function preloadImage(url) {
  const img = new Image();
  img.src = url;
}

module.exports = {
  isValidEmail,
  PrefixedEvent,
  toProps,
  between,
  randomId,
  safe,
  getOffsetTop,
  textSize,
  textTitle,
  mediumText,
  moreInfoText,
  preloadImage,
  year,
};
