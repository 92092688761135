const { html } = require('@forgjs/noframework');
const link = require('./link');
const Hamburger = require('./hamburger');

const Nav = (eventManager) => {
  const hamburger = Hamburger();
  const DomElement = html`
  <nav class="nav">
    <ul class="nav__links">
      <div class="nav__left">
        <li class="nav__hamburger">${hamburger}</li>
        ${link('/projects', html`<li class="nav__name nav__link">Spandita Malik</li>`)}
      </div>
    </ul>
  </nav>`;

  hamburger.addEventListener('click', () => {
    eventManager.emit('open-overlay');
  });

  eventManager.subscribe('open-overlay', () => {
    hamburger.classList.toggle('is-active');
  });

  return DomElement;
};

module.exports = Nav;
