const { html, $ } = require('@forgjs/noframework');
const Icon = require('../components/Icon');

const Player = (grids, eventManager) => {
  const leftIcon = Icon('chevron-left', { class: 'commercial__payer__arrow' });
  const rightIcon = Icon('chevron-right', { class: 'commercial__payer__arrow' });
  const DomElement = html`<div class="commercial__payer">
   ${leftIcon}
   <img class="commercial__payer__image"/>
   ${rightIcon}
   <div class="info-player">
    <h2 class="title"></h2>
    <p class="medium"></p>
    <p class="size"></p>
   </div>
  </div>`;
  const playerImage = $('.commercial__payer__image', DomElement);
  let gridIndex = 0;
  let imageIndex = 0;

  const mediumElem = $('.medium', DomElement);
  const sizeElem = $('.size', DomElement);
  const titleElem = $('.title', DomElement);

  playerImage.onload = () => {
    DomElement.classList.add('visible');
  };

  eventManager.subscribe('player-mode', (i, j) => {
    gridIndex = i;
    imageIndex = j;
    console.log(grids, i);
    if(!grids[gridIndex]) {
      return;};
    const img = grids[gridIndex][imageIndex];

    console.log(grids, gridIndex, imageIndex);

    const largeSrc = img.src ? img.src : img;
    playerImage.setAttribute('src', largeSrc);
    if (img.title) {
      mediumElem.innerText = img.medium;
      sizeElem.innerText = img.size;
      titleElem.innerText = img.title;
      $(".info-player", DomElement).classList.remove("hide");
    } else {
      mediumElem.innerText = "";
      sizeElem.innerText = "";
      titleElem.innerText = "";
      $(".info-player", DomElement).classList.add("hide");
    }
  });

  leftIcon.addEventListener('click', () => {
    if (grids[gridIndex] && grids[gridIndex][imageIndex - 1]) {
      imageIndex -= 1;
    } else if (grids[gridIndex - 1]) {
      gridIndex -= 1;
      imageIndex = grids[gridIndex].length - 1;
      eventManager.emit('prev-grid');
    } else {
      gridIndex = grids.length - 1;
      imageIndex = grids[gridIndex].length - 1;
      eventManager.emit('prev-grid');
    }
    const img = grids[gridIndex][imageIndex];
    const largeSrc = img.src ? img.src : img;
    playerImage.setAttribute('src', largeSrc);
    if (img.title) {
      mediumElem.innerText = img.medium;
      sizeElem.innerText = img.size;
      titleElem.innerText = img.title;
      $(".info-player", DomElement).classList.remove("hide");
    } else {
      mediumElem.innerText = "";
      sizeElem.innerText = "";
      titleElem.innerText = "";
      $(".info-player", DomElement).classList.add("hide");
    }
  });

  rightIcon.addEventListener('click', () => {
    if (grids[gridIndex] && grids[gridIndex][imageIndex + 1]) {
      imageIndex += 1;
    } else if (grids[gridIndex + 1]) {
      imageIndex = 0;
      gridIndex += 1;
      eventManager.emit('next-grid');
    } else {
      gridIndex = 0;
      imageIndex = 0;
      eventManager.emit('next-grid');
    }
    const img = grids[gridIndex][imageIndex];
    const largeSrc = img.src ? img.src : img;
    if (img.title) {
      mediumElem.innerText = img.medium;
      sizeElem.innerText = img.size;
      titleElem.innerText = img.title;
      $(".info-player", DomElement).classList.remove("hide");
    } else {
      mediumElem.innerText = "";
      sizeElem.innerText = "";
      titleElem.innerText = "";
      $(".info-player", DomElement).classList.add("hide");
    }
    playerImage.setAttribute('src', largeSrc);
  });


  DomElement.addEventListener('click', (e) => {
    if (e.target === DomElement) {
      DomElement.classList.remove('visible');
    }
  });

  return DomElement;
};

module.exports = Player;
