module.exports = [
  {
    title: 'Vadhu',
    description: `Over the last one year, the community of women whom I've worked with before, came together to collaborate in a disparate world of thought and support during the fragmented times we found ourselves in. We collaborated through international mail and phone calls, but more importantly during the process of creation, we could build a community to support, hear and listen to each other in a different but significant manner, whatever deemed necessary. During these fragmented times, the community of women I work with have gone through mayhem of constant grief and ever changing lives in a pandemic; it became crucial to sustain the community. 
    While we have talked a lot about change, the kind that was inevitable and the kind of hopeful daydreams, the past and the present have existed and erupted on occasions simultaneously. The women met in their backyards, bringing with them photo albums, mostly wedding photographs and the conversation started; they talked of themselves, the self they saw in the photographs of the past, a narrative of someone transformed as we walked through the memory lanes of many decades. Memories that existed in the photograph, skipped timelines, often jumped, sometimes ground to halt on different occasions informed a new shape of the memory. They embroider the portraits of the past, recalling, recollecting, remembering and reclaiming the narrative of the portrait in sync with the present; the language of embroidery reshapes the memory of the photograph. `,
    images: [
      {
        title: 'Meena',
        subtitle: 'Photographic transfer print on khaddar fabric, phulkari silk thread embroidery',
        h: 28,
        w: 39,
        src: '/img/images/1 Projects/4 Vadhu/10.jpg',
      },
      {
        title: 'Rukmesh Kumari III',
        subtitle: 'Photographic transfer print on khaddar fabric, phulkari silk thread embroidery',
        h: 28,
        w: 38.5,
        src: '/img/images/1 Projects/4 Vadhu/7.jpg',
      },
      {
        title: 'Praween Devi III',
        subtitle: 'Photographic transfer print on khaddar fabric, phulkari silk thread embroidery',
        h: 28,
        w: 39,
        src: '/img/images/1 Projects/4 Vadhu/8.jpg',
      },
      {
        title: 'Radha Rani III',
        subtitle: 'Photographic transfer print on khaddar fabric, phulkari silk thread embroidery',
        h: 28,
        w: 40,
        src: '/img/images/1 Projects/4 Vadhu/9.jpg',
      },
      {
        title: 'Radha',
        subtitle: 'Photographic transfer print on khaddar fabric, phulkari silk thread embroidery',
        h: 28.5,
        w: 40,
        src: '/img/images/1 Projects/4 Vadhu/1.jpg',
      },
      {
        title: 'Sarfaraz II',
        subtitle: 'Photographic transfer print on khadi fabric, zardozi embroidery, beadwork',
        h: 26,
        w: 38.5,
        src: '/img/images/1 Projects/4 Vadhu/2.jpg',
      },
      {
        title: 'Praween Devi II',
        subtitle: 'Photographic transfer print on khaddar fabric, phulkari silk thread embroidery',
        h: 37.5,
        w: 30.5,
        src: '/img/images/1 Projects/4 Vadhu/3.jpg',
      },
      {
        title: 'Rajni Mehra II',
        subtitle: 'Photographic transfer print on khaddar fabric, phulkari silk thread embroidery',
        h: 25,
        w: 31.5,
        src: '/img/images/1 Projects/4 Vadhu/4.jpg',
      },
      {
        title: 'Sindhu II',
        subtitle: 'Photographic transfer print on khadi fabric, zardozi embroidery, beadwork, coin embroidery',
        h: 40,
        w: 49,
        src: '/img/images/1 Projects/4 Vadhu/5.jpg',
      },
      {
        title: 'Radha Rani II',
        subtitle: 'Photographic transfer print on khaddar fabric, phulkari silk thread embroidery',
        h: 26,
        w: 49,
        src: '/img/images/1 Projects/4 Vadhu/6.jpg',
      },
    ],
  },
  {
    title: 'Home: Obscured',
    description: ``,
    images: [
      {
        title: 'Memories on Desk',
        subtitle: 'Sun-printing, Phulkari silk thread embroidery on Khaddar fabric',
        h: 32,
        w: 40,
        src: '/img/images/1 Projects/Home Obscured/1.jpg',
      },
      {
        title: 'Untitled I',
        subtitle: 'Sun-printing, Zardozi and Gotta-Patti on Khadi fabric',
        h: 30,
        w: 25,
        src: '/img/images/1 Projects/Home Obscured/2.jpg',
      },
      {
        title: 'Salwar-Kameez on Clothesline',
        subtitle: 'Sun-printing, Phulkari silk thread embroidery on Khaddar fabric',
        h: 32,
        w: 40,
        src: '/img/images/1 Projects/Home Obscured/3.jpg',
      },
      {
        title: 'Untitled III',
        subtitle: 'Sun-printing, Phulkari silk thread embroidery on Khaddar',
        h: 16,
        w: 22,
        src: '/img/images/1 Projects/Home Obscured/4.jpg',
      },
  ],
  },
  {
    title: 'Nā́rī 2020',
    description: `There has always been a sense of legacy being passed among women through this language of embroidery and handcraft, inherited by generations of women and passed along, to break the oppressor by simple but significant hand movements captured on fabric, written in thread.
    It is a peculiar sense of belonging and safety found in the private spaces of the unfamiliar. There is an ease in unloading the pain in the agony of another; there is a strange trust and care in these private places, shared by women, known to women. These collaborations created a connection between me and the women in our shared language of art; by listening through our inherited language of embroidery, I learnt the true meaning of nā́rī .
    `,
    images: [
      {
        title: 'Parween Devi',
        subtitle: 'Photographic transfer Print on Khaddar, Phulkari silk thread Embroidery',
        h: 43.5,
        w: 53,
        src: '/img/images/1 Projects/nari2020/1.jpg',
      },
      {
        title: 'Sarfaraz',
        subtitle: 'Photographic transfer Print on Khadi, Zardozi Embroidery, Appliqué',
        h: 54.5,
        w: 32.5,
        src: '/img/images/1 Projects/nari2020/2.jpg',
      },
      {
        title: 'Renu',
        subtitle: 'Photographic transfer Print on Khadi, Gotta Patti and Zardozi Embroidery',
        h: 36.5,
        w: 54,
        src: '/img/images/1 Projects/nari2020/3.jpg',

      },
      {
        title: 'Rukmesh Kumari',
        subtitle: 'Photographic transfer Print on Khaddar, Phulkari silk thread Embroidery',
        h: 40,
        w: 49,
        src: '/img/images/1 Projects/nari2020/4.jpg',
      },
      {
        title: 'Aayesha',
        subtitle: 'Photographic transfer Print on Khadi, Embroidery, Crochet',
        h: 38,
        w: 46.5,
        src: '/img/images/1 Projects/nari2020/5.jpg',
      },
      {
        title: 'Sindhu',
        subtitle: 'Photographic transfer Print on Khadi, Embroidery, Appliqué, Beadwork',
        h: 22.5,
        w: 32,
        src: '/img/images/1 Projects/nari2020/6.jpg',
      },
      {
        title: 'Renu Devi',
        subtitle: 'Photographic transfer Print on Khadi, Zardozi Embroidery',
        w: 28,
        h: 22.5,
        src: '/img/images/1 Projects/nari2020/7.jpg',
      },
    ],
  },
  {
    title: 'Nā́rī 2019',
    description: `In Sanskrit, nā́rī means woman, wife, female, or an object regarded as feminine but can also mean sacrifice. For the project nā́ rī, I researched villages in India that have small communities of women who use fabric and embroidery as a way of gaining financial freedom. I traveled to Lucknow, Jaipur and Chamkaur Sahib where I photographed and interviewed several women from these communities. By gaining access to these individuals I was able to ask them about their thoughts on patriarchal structures of Indian society and about their harsh economic and social realities.
    From these conversations, I decided to create artworks where the women embroidered on top of their portraits that were shot and printed on the fabric used in the women’s specific communities.
    These artistic collaborations subvert the idea of the artist as the main producer by giving each woman her own creative entity within her own commercial craft. It also engages the problem of representation in portrait photography as addressed by giving women control over their image.
    By traveling to these women's private spaces I seek an understanding that is only possible with my presence and collaboration that creates a connection within our shared language of art; by listening, I learn the true meaning of nā́rī.`,
    images: [
      {
        title: 'Kosar',
        subtitle: 'Photographic transfer Print on Khadi, Gotta Patti and Zardozi Embroidery',
        h: 26,
        w: 35,
        src: '/img/images/1 Projects/nari/1.jpg',
      },
      {
        title: 'Fozia',
        subtitle: 'Photographic transfer Print on Khadi, Embroidery',
        h: 23,
        w: 19,
        src: '/img/images/1 Projects/nari/2.jpg',

      },
      {
        title: 'Nuzrat Praween',
        subtitle: 'Photographic transfer print on Voile Fabric, Chikankari embroidery',
        h: 36.5,
        w: 43,
        src: '/img/images/1 Projects/nari/3.jpg',
      },
      {
        title: 'Radha Rani',
        subtitle: 'Medium: Photographic transfer print on Khaddar Fabric, Phulkari Silk thread embroidery',
        h: 41.5,
        w: 44,
        src: '/img/images/1 Projects/nari/4.jpg',

      },
      {
        title: 'Lakhbir Kaur',
        subtitle: 'Photographic transfer print on Khaddar Fabric, Phulkari Silk thread embroidery',
        h: 39,
        w: 41,
        src: '/img/images/1 Projects/nari/5.jpg',
      },
      {
        title: 'Jagdeep Kaur & Guldeep Kaur',
        subtitle: 'Medium: Photographic transfer print on Khaddar Fabric, Phulkari Silk thread embroidery',
        h: 17.75,
        w: 22.5,
        src: '/img/images/1 Projects/nari/6.jpg',
      },
      {
        title: 'Firoza',
        subtitle: 'Photographic transfer Print on Khadi, Gotta Patti and Mirror Embroidery',
        w: 23,
        h: 33,
        src: '/img/images/1 Projects/nari/7.jpg',

      },
      {
        title: 'Poonam',
        subtitle: 'Photographic transfer print on Voile Fabric, Chikankari embroidery',
        h: 34,
        w: 41,
        src: '/img/images/1 Projects/nari/8.jpg',

      },
      {
        title: 'Kirna Devi',
        subtitle: 'Medium: Photographic transfer print on Khaddar Fabric, Phulkari Silk thread embroidery',
        h: 11.5,
        w: 16,
        src: '/img/images/1 Projects/nari/9.jpg',
      },
      {
        title: 'Rajni Mehra',
        subtitle: 'Photographic transfer print on Khaddar Fabric, Phulkari Silk thread embroidery',
        h: 19.5,
        w: 34,
        src: '/img/images/1 Projects/nari/10.jpg',

      },
    ],
  },
  {
    title: 'Untitled',
    description: '',
    images: [
      {
        title: 'Untitled 1',
        subtitle: 'Muslin, Heat Transfer Print, Embroidery',
        src: '/img/images/1 Projects/MaterialExploration/1.jpg',
      },
      {
        title: 'Untitled 2',
        subtitle: 'Plaster Cast, Heat Transfer Print',
        src: '/img/images/1 Projects/MaterialExploration/2.jpg',
      },
      {
        title: 'Untitled 3',
        subtitle: 'Muslin, Wire Mesh, Heat Transfer Print',
        src: '/img/images/1 Projects/MaterialExploration/3.jpg',
      },
    ],
  },
  {
    title: 'Last portraits of her',
    description: '`Last Portraits of Her` is an abstraction of the illegally printed photographs of the corpses of the gang rape victims  in Indian print media in 2012 and 2018. The portraits of Jyoti Singh and Asifa Bano are turned into memoirs as an attempt to do justice to their last portraits. The sheer brutality of the gang rape and murder of the two victims do not suffice to describe them. The event of their death should not and cannot be the way we illustrate their last portraits. This work is an attempt to re-imagine them as women of various characteristics apart from their death. I want the viewer to look at these last portraits of them and tell me what they see, what these portraits could be.',
    images: [
      {
        src: '/img/images/1 Projects/0 Last portraits of her/b_Last Portraits of Her_1.jpg',
      },
      {
        src: '/img/images/1 Projects/0 Last portraits of her/c_Last Portraits of Her_2.jpg',
      }],
  },
  {
    title: 'Being a woman',
    description: '‘Being A Woman’ sheds light on the rape culture and violence against women in India. The project reflects from the political and media portrayal of the status of a women in twenty-first century India. In a country that prays to goddesses for good fortune, being a woman is no short of a curse. The abuse and inequality has reached a stage where women have adjusted their lives to a life of injustice. The projects reflects from the current scenario and urges to deflect from the stagnant mindset.',
    images: [
      { src: '/img/images/1 Projects/1 Being a woman/1.jpg' },
      { src: '/img/images/1 Projects/1 Being a woman/10.jpg' },
      { src: '/img/images/1 Projects/1 Being a woman/11.jpg' },
      { src: '/img/images/1 Projects/1 Being a woman/12.jpg' },
      { src: '/img/images/1 Projects/1 Being a woman/13.jpg' },
      { src: '/img/images/1 Projects/1 Being a woman/2.jpg' },
      { src: '/img/images/1 Projects/1 Being a woman/3.jpg' },
      { src: '/img/images/1 Projects/1 Being a woman/4.jpg' },
      { src: '/img/images/1 Projects/1 Being a woman/5.jpg' },
      { src: '/img/images/1 Projects/1 Being a woman/6.jpg' },
      { src: '/img/images/1 Projects/1 Being a woman/7.jpg' },
      { src: '/img/images/1 Projects/1 Being a woman/8.jpg' },
      { src: '/img/images/1 Projects/1 Being a woman/9.jpg' },
    ],
  },
  {
    title: 'Resilient',
    description: '‘Resilient’ is a project to identify the relationship between the photographer and the image. The aggressive obsession of defining the right photograph in the perfect moment and the aftermath of the haunting experience of the incapacity to recreate the concrete evidence of that moment.\n',
    images: [
      { src: '/img/images/1 Projects/3 Resilient/a.jpg' },
      { src: '/img/images/1 Projects/3 Resilient/b.jpg' },
      { src: '/img/images/1 Projects/3 Resilient/c.jpg' },
      { src: '/img/images/1 Projects/3 Resilient/d.jpg' },
      { src: '/img/images/1 Projects/3 Resilient/e.jpg' },
      { src: '/img/images/1 Projects/3 Resilient/f.jpg' },
      { src: '/img/images/1 Projects/3 Resilient/g.jpg' },
      { src: '/img/images/1 Projects/3 Resilient/h.jpg' },
      { src: '/img/images/1 Projects/3 Resilient/i.jpg' },
    ],
  },
  {
    title: 'Its not real',
    description: '‘It’s Not Real’, a combination of embroidery and photographs of vintage has been used to sham stereotypes. Over time the media portrayals and the gender roles have somewhat made us delusional. We all are able to categorize the dos and don’ts and the misfits and fits, and we all have mastered the act of judging one based on these categories. I have attempted to dig into the reality hidden behind these stereotypes and make us question our opinions. Digitally printed fabric is used as the base and paintings from different art-movements by famous artists are embroidered over the photograph. The meaning of the painting and the contradicting essence of the photograph are used to depict the message.\n',
    images: [
      { src: '/img/images/1 Projects/8 Its not real/1.jpg' },
      { src: '/img/images/1 Projects/8 Its not real/10.jpg' },
      { src: '/img/images/1 Projects/8 Its not real/2.jpg' },
      { src: '/img/images/1 Projects/8 Its not real/3.jpg' },
      { src: '/img/images/1 Projects/8 Its not real/4.jpg' },
      { src: '/img/images/1 Projects/8 Its not real/5.jpg' },
      { src: '/img/images/1 Projects/8 Its not real/6.jpg' },
      { src: '/img/images/1 Projects/8 Its not real/7.jpg' },
      { src: '/img/images/1 Projects/8 Its not real/8.jpg' },
      { src: '/img/images/1 Projects/8 Its not real/9.jpg' },
    ],
  },
];
