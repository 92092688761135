const { html } = require('@forgjs/noframework');
const Navigation = require('../components/Nav2');
const Footer = require('../components/Footer');
const Links = require('../components/links');

const Cv = (Nav = Navigation) => {
  const DomElement = html`
    <div class="cv">
      ${Nav()}
      <div class="cv__container">
      <!-------------------------------------------------->
      <h1 class="cv__title">EDUCATION</h1>

      <h2 class="cv__sub-title"><span>Masters of Fine Art, Major: Photography</span><span>2017-19</span></h2>
      <p>Parsons School of Design, The New School University, New York, NY</p>

     <h2 class="cv__sub-title"><span>Bachelor of Design, Major: Fashion Design</span><span>2013-17</span></h2>
      <p>National Institute of Fashion Technology, New Delhi, India</p>

     <h2 class="cv__sub-title"><span>International Semester Study, Major: Fashion Management</span><span>2016</span></h2>
     <p>École Nationale Supérieure des Arts et Industries Textiles, Lille, France</p>
     <!-------------------------------------------------->


      <h1 class="cv__title">SOLO EXHIBITIONS</h1>

      <h2 class="cv__sub-title"><span>Jāḷī : Meshes of Resistance</span><span>2023</span></h2>
      <p>Kemper Museum of Contemporary Art, Kansas City MO</p>

      <h2 class="cv__sub-title"><span>Vadhu: The Embroidered Bride</span><span>2023</span></h2>
      <p>Karl and Helen Burger Gallery, Kean University, Union, NJ</p>

      <h2 class="cv__sub-title"><span>Nā́rī</span><span>2022</span></h2>
      <p>Oregon Center for the Photographic Arts, Portland, OR</p>

      <h2 class="cv__sub-title"><span>Vadhu: The Embroidered Bride</span><span>2021</span></h2>
      <p>Baxter St. CCNY, New York, NY</p>

      <h2 class="cv__sub-title"><span>Spandita Malik: Threads of Identity</span><span>2021</span></h2>
      <p> Visual Art Centre of New Jersey, Summit, NJ </p>

      <h1 class="cv__title">GROUP EXHIBITIONS</h1>

      <h2 class="cv__sub-title"><span>The New Contemporaries Vol III</span><span>2023</span></h2>
      <p>Residency Art Gallery, Inglewood, CA</p>

      <h2 class="cv__sub-title"><span>Encontros da Imagem</span><span>2023</span></h2>
      <p>International Photography and Visual Arts Festival, Braga, Portugal</p>

      <h2 class="cv__sub-title"><span>New Discoveries in Contemporary Photography</span><span>2023</span></h2>
      <p>Photo London, Somerset House, London, UK</p>

      <h2 class="cv__sub-title"><span>In the Different Light</span><span>2022</span></h2>
      <p>Rockefeller Capital, New York, NY</p>

      <h2 class="cv__sub-title"><span>Labor & Materials</span><span>2022</span></h2>
      <p>21c Museum Hotels, Kansas City, MO</p>

      <h2 class="cv__sub-title"><span>Say the dream was real and the wall imaginary</span><span>2022</span></h2>
      <p>Jane Lombard Gallery, NY</p>

      <h2 class="cv__sub-title"><span>Like Wildflowers</span><span>2022</span></h2>
      <p>JNY Culture Club, New York, NY</p>

      <h2 class="cv__sub-title"><span>BeautifulWorld(s): KCAI Biennial</span><span>2021</span></h2>
      <p>H&R Block Artspace, Kansas City, MO</p>

      <h2 class="cv__sub-title"><span>Photo London: indigo+madder</span><span>2021</span></h2>
      <p>Somerset House, London, UK</p>

      <h2 class="cv__sub-title"><span>Zwischenzeit</span><span>2021</span></h2>
      <p>KUNSTWERK Collection Klein, Eberdingen-Nussdorf, Germany</p>

      <h2 class="cv__sub-title"><span>In Between Yesterday Today and Tomorrow</span><span>2021</span></h2>
      <p>Wallworks NY, Bronx, NY</p>

      <h2 class="cv__sub-title"><span>Shifting Sands</span><span>2021</span></h2>
      <p>Chashama, New York, NY</p>

      <h2 class="cv__sub-title"><span>Diasporic Rhizome</span><span>2021</span></h2>
      <p>South Asia Institute (Virtual)</p>
      
      <h2 class="cv__sub-title"><span>What We Become When We Are Unbound</span><span>2021</span></h2>
      <p> Project for Empty Spaces, Newark, NJ </p>
      
      
      <h2 class="cv__sub-title"><span>ˈtəch *</span><span>2020</span></h2>
      <p> Marisa Newman Projects, New York, NY</p>
      
      <h2 class="cv__sub-title"><span>Projections</span><span>2020</span></h2>
      <p>Photo Vogue Festival, photovoguefestival.vogue.it</p>
      
      <h2 class="cv__sub-title"><span>Vantage Point Sharjah 8</span><span>2020</span></h2>
      <p>Sharjah Art Foundation, Sharjah, United Arab Emirates</p>
      
      <h2 class="cv__sub-title"><span>Radical Imagination</span><span>2020</span></h2>
      <p> Sakhi for South Asian Women, New York, NY</p>
      
      <h2 class="cv__sub-title"><span>In/Between: Transfigure</span><span>2020</span></h2>
      <p>Ford Foundation Live Gallery, New York Live Arts, New York, NY</p>

      <h2 class="cv__sub-title"><span>Reclamation</span><span>2020</span></h2>
      <p>Petite Gallery, Brooklyn, NY</p>

      <h2 class="cv__sub-title"><span>Being Bare: 2019 MFA in Photography Thesis Exhibition</span><span>2019</span></h2>
       <p>Arnold and Sheila Aronson Galleries, New York, NY</p>
 
      <h2 class="cv__sub-title"><span>Art 52nd Street,The 6th Annual International Young Artist Exhibition</span><span>2019</span></h2>
       <p>Gallery MC, New York, NY</p>
 
      <h2 class="cv__sub-title"><span>Omens and Predictions Exhibition</span><span>2019</span></h2>
       <p>lenscratch.com, curated by Aline Smithson</p>
 
      <h2 class="cv__sub-title"><span>Memories</span><span>2018</span></h2>
       <p>Millepiani Gallery, Rome, Italy</p>
 
      <h2 class="cv__sub-title"><span>Disquieted Drift</span><span>2018</span></h2>
       <p>Pingyao International Photography Festival, Pingyao, China</p>
 
      <h2 class="cv__sub-title"><span>In Relation</span><span>2018</span></h2>
       <p>Photoville (Dumbo), Brooklyn, New York, NY</p>
 
      <h2 class="cv__sub-title"><span>Studio Vortex by Antoine d’Agata</span><span>2018</span></h2>
       <p>Voies off Gallery, Arles, France</p>
 
      <h2 class="cv__sub-title"><span>Auckland Festival of Photography</span><span>2018</span></h2>
       <p>Auckland, New Zealand</p>
 
      <h2 class="cv__sub-title"><span>India Photo Festival</span><span>2018</span></h2>
       <p>Mumbai, India</p>
 
      <h2 class="cv__sub-title"><span>Apparatus of Discomfort</span><span>2017</span></h2>
       <p>Photoville (Dumbo), New York, NY</p>
 
      <h2 class="cv__sub-title"><span>Thesis Collection Show NIFT</span><span>2017</span></h2>
      <p>The Stainless Gallery, New Delhi, India</p>
 
      <!-------------------------------------------------->
     

      <h1 class="cv__title">SELECTED RESIDENCIES</h1>

      <h2 class="cv__sub-title"><span>Charlotte Street Foundation Studio Residency Program, Kansas City, MO</span><span>2022</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>The Silver Arts Projects Residency, NY</span><span>2021</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>Bemis Center for Contemporary Arts, Omaha, NE</span><span>2021</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>The Center for Photography, Woodstock, NY</span><span>2021</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>Feminist Incubator Residency, Project for Empty Spaces, Newark, NJ</span><span>2020</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>Baxter St Workspace Residency, New York, NY</span><span>2020</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>The Spruce Art Residency, Indiana, PA </span><span>2019</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>Studio Vortex Artist Residency by Antoine d'Agata, Arles, France</span><span>2018</span></h2>
      <p></p>



      <!-------------------------------------------------->
      <h1 class="cv__title">AWARDS AND FELLOWSHIPS</h1>

      <h2 class="cv__sub-title"><span>Discovery Awards (Finalist)</span><span>2023</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>Charlotte Street Visual Art Award (Finalist)</span><span>2023</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>2022 LensCulture Critics' Choice Award</span><span>2022</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>The Hopper Prize (Finalist)</span><span>2022</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>Inge Morath Award (Finalist)</span><span>2022</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>The 30: New and Emerging Photographers Award</span><span>2022</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>AICAD Post Graduate Teaching Fellowship</span><span>2021</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>Aperture Portfolio Prize (Finalist)</span><span>2021</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>En Foco Photography Fellowship</span><span>2021</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>New York Times Portfolio Review </span><span>2021</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>The Foam Paul Huf Award 2021 (Nominated)</span><span>2021</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>PHmuseum 2020 Women Photographers Grant (Honorable Mention)</span><span>2020</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>Firecracker Photographic Grant</span><span>2020</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>South Asian Arts Resiliency Fund Grant</span><span>2020</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>Foundation for Contemporary Arts FCA Emergency Grants</span><span>2020</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>New York Foundation for the Arts (NYFA) Immigrant Artist Mentoring Program</span><span>2020</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>AICAD Post Graduate Teaching Fellowship (Nominee)</span><span>2020</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>Parsons Photography Programmatic Scholarship Award </span><span>2019</span></h2>
      <p></p>

      
      <h2 class="cv__sub-title"><span>Parsons Graduate Travel Award Recipient  from The New School University </span><span>2018</span></h2>
      <p></p>
      
      <h2 class="cv__sub-title"><span>Dean’s Merit Scholarship, The New School University</span><span>2017</span></h2>
      <p></p>


      <!-------------------------------------------------->
      <h1 class="cv__title">REVIEWS</h1>
      <div class="logos">
        <a target="_blank" href="https://www.artsy.net/article/artsy-editorial-6-rising-contemporary-artists-traditional-craft-techniques"><img src="/img/logos/logo6.jpg"></a>
        <a target="_blank" href="https://www.buzzfeednews.com/article/piapeterson/spandita-malik-photography-women-india"><img src="/img/logos/logo5.jpg"></a>
        <a target="_blank" href="https://2022.the30photographers.com/p/17"><img src="/img/logos/logo1.jpg"></a>
        <a target="_blank" href="https://museemagazine.com/culture/2020/3/17/woman-crush-wednesday-spandita-malik"><img src="/img/logos/logo4.jpg"></a>
        <a target="_blank" href="https://www.nst.com.my/lifestyle/sunday-vibes/2021/10/733131/photography-and-perspective-photo-london-2021"><img src="/img/logos/logo3.jpg"></a>
        <a target="_blank" href="https://www.thenews.com.pk/tns/detail/821207-the-art-of-satire?fbclid=IwAR3POb_tFdHDzLGI5r4PYQTEMXr0gbxV0K5voxmd4hjowN1UM2lQap3vRfc"><img src="/img/logos/logo2.jpg"></a>
        <a target="_blank" href="https://www.aajsamaaj.com/khas-khabar/seeing-womens-oppression-from-an-independent-perspective/"><img src="/img/logos/logo.jpg"></a>
        <a target="_blank" href="https://news.artnet.com/art-world/editors-picks-november-1-2021-2022645"><img src="/img/logos/logo7.jpg"></a>
        <a target="_blank" href="https://www.1854.photography/2020/09/spandita-malik-i-was-never-happy-with-a-photograph-being-a-photograph/"><img src="/img/logos/logo8.jpg"></a>
      </div>


      <!-------------------------------------------------->
      <h1 class="cv__title">INVITED TALKS</h1>

      <h2 class="cv__sub-title"><span>Guest Speaker, Southern Alleghenies Museum of Art Altoona, Donald M. Robinson Photography Biennial Symposium</span><span>2023</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>Visiting Artist Lecture, Lesley University, MFA Photography</span><span>2023</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>Visiting Artist Lecture, University of Kansas, Photography Senior class talk</span><span>2022</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>Visiting Artist Lecture, Greenfield Community College, Art Department- Photography</span><span>2022</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>“Life Long Service Through The Arts”  Speaker Series, Artist Year, Featured Speaker</span><span>2021</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>“Eileen Quinlan + Spandita Malik” Baxter St CCNY, Public ExhibitionProgram</span><span>2021</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>“Vadhu: The Embroidered Bride” Parsons School of Design, Exhibition Artist Talk</span><span>2021</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>Artist talk, Lesley University, Virtual Artist Talk</span><span>2021</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>Leading Through Art: The Role of Art & Artist in promoting Social Justice, Lead New Jersey (Panelist)</span><span>2021</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>Sexual Justice Symposium, Gender and Sexuality Studies Institute, The New School (Panelist)</span><span>2021</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>Visiting Artist Lecture, University of Texas, Virtual Artist Talk</span><span>2021</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>“What we Become When We Are Unbound: Artist Talk and Exhibition Tour” Project for Empty Space, New Jersey, Public Programming</span><span>2021</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>“Coffee Talk: Artist Talk” Project for Empty Space, New Jersey, Artist Talk</span><span>2021</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>“Artist Talk: Threads of Identity” Visual Art Center of New Jersey, Virtual Programming</span><span>2021</span></h2>
      <p></p>



      <h2 class="cv__sub-title"><span>Edinburgh Napier University, BA Photography, Artist Talk Virtual Presentation</span><span>2021</span></h2>
      <p></p>
      
      <h2 class="cv__sub-title"><span>Parsons School of Design, The New School, MFA Photography, Alumni Talk</span><span>2020</span></h2>
      <p></p>
  
      <h2 class="cv__sub-title"><span>“Cabin Fever Artist Talk Series” Visual Art Center of New Jersey, Virtual Artist Talk</span><span>2020</span></h2>
      <p></p>

      <h2 class="cv__sub-title"><span>“Disquieted Drift: Lecture Series,” Pingyao International Photography Festival, Artist Talk</span><span>2018</span></h2>
      <p></p>
      


    </div>
    ${Footer()}
    ${Links()}
  </div>
  `;

  return DomElement;
};

module.exports = Cv;
