const { html } = require('@forgjs/noframework');
const Cv = require('../../CV');
const Nav = require('../../components/Nav');
const eventManager = require('../eventManager');
const Overlay = require('../Overlay');
const projects = require('../../projects');

const CvHome = () => {
  const DomElement = html`
    <div>
    ${Overlay(projects)}
    ${Cv(() => Nav(eventManager))}
    </div>
  `;

  return DomElement;
};

module.exports = CvHome();
