const { html, smoothScrollTo, $ } = require('@forgjs/noframework');
const Nav = require('../components/Nav2');
const Grid = require('./Grid');
const Player = require('./player');
const commercials = require('./comercials');
const Links = require('../components/links');
const Footer = require('../components/Footer');
const { between, getOffsetTop } = require('../utils');
const eventManager = require('./eventManager');

window.evtm = eventManager;

const Commercial = () => {
  const grids = commercials.map((images, i) => Grid(images, i));
  const DomElement = html`
  <div class="comercial">
    ${Nav()}
    ${Player(commercials, eventManager)}
    ${grids}
    ${Footer()}
    ${Links()}
  </div>`;
  const max = 30;
  let limit = 0;
  let i = 0;
  let scrolling = false;

  const scrollTo = (index) => {
    smoothScrollTo($('html'), getOffsetTop(grids[index]) - 100, 500);
  };

  eventManager.subscribe('next-grid', () => {
    i = (i + 1) % grids.length;
    scrollTo(i);
  });

  eventManager.subscribe('prev-grid', () => {
    i = i - 1 > 0 ? i - 1 : grids.length;
    scrollTo(i);
  });

  DomElement.addEventListener('wheel', (e) => {
    if (window.location.pathname !== '/commercials') return;

    e.preventDefault();
    const delta = Math.sign(e.wheelDeltaY || -1 * e.deltaY);
    if (Math.sign(limit) !== delta) {
      limit = 0;
    }
    limit += !scrolling ? delta : 0;
    if (Math.abs(limit) > max && scrolling === false) {
      i = between(i - Math.sign(limit), 0, grids.length - 1);
      scrolling = true;
      setTimeout(() => {
        scrolling = false;
      }, 500);
      limit = 0;
      scrollTo(i);
    }
  }, { passive: false });

  return DomElement;
};

module.exports = Commercial();
