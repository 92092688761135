const { html } = require('@forgjs/noframework');
const AboutMe = require('../../AboutMe');
const Nav = require('../../components/Nav');
const eventManager = require('../eventManager');
const Overlay = require('../Overlay');
const projects = require('../../projects');

const AboutMeHome = () => {
  const DomElement = html`
    <div>
    ${Overlay(projects)}
    ${AboutMe(Nav(eventManager))}
    </div>
  `;

  return DomElement;
};

module.exports = AboutMeHome();
