const { html } = require('@forgjs/noframework');

const Hamburger = () => {
  const DomElement = html`<div class="hamburger hamburger--collapse">
  <div class="hamburger-box">
    <div class="hamburger-inner-before"></div>
    <div class="hamburger-inner-after"></div>
    <div class="hamburger-inner"></div>
  </div>
</div>`;

  return DomElement;
};

module.exports = Hamburger;
