const { EventManager } = require('@forgjs/noframework');

const emiter = new EventManager();

window.addEventListener('popstate', () => {
  const path = document.location.pathname.match(/^\/[a-z]+(\/)/g,"") ? 
document.location.pathname.slice(0, document.location.pathname.length - 1) : document.location.pathname;
  emiter.emit('reroot', path);
});

module.exports = emiter;
