const { html, emptyElement } = require('@forgjs/noframework');
const {
  moreInfoText,
  textSize,
  mediumText,
} = require('../utils');

const gridImage = (img, eventManager, projectIndex, i) => {
  const DomElement = html`
  <div class="grid__cell">
    <img class="grid__img" src="${img.src}" loading="lazy">
    <div class="grid__cell__info">
      <h3>${img.title}</h3>
      <p>${moreInfoText(img)}</p>
      <p>${textSize(img)}</p>
      <p>${mediumText(img)}</p>
    </div>
  </div>`;

  DomElement.addEventListener('click', () => {
    eventManager.emit('player-mode', projectIndex, i);
  });
  return DomElement;
};

const Grid = (projects, eventManager) => {
  let currentProject = 0;
  const DomElement = html`<div class="grid" style="display:none">
    <div class="row"></div>
    <div class="row"></div>
  </div>`;

  const renderGrid = (images) => {
    images.forEach((img, i) => {
      DomElement.children[i % 2]
        .appendChild(gridImage(img, eventManager, currentProject, i));
    });
  };

  renderGrid(projects[currentProject].images);

  eventManager.subscribe('player-mode', (iProject) => {
    currentProject = iProject;
    DomElement.style.display = 'none';
  });

  DomElement.addEventListener('contextmenu', (e) => {
    e.preventDefault();
    e.stopPropagation();
  });

  eventManager.subscribe('project-change', (pIndex) => {
    if (pIndex !== currentProject) {
      Array.from(DomElement.children).forEach(emptyElement);
      currentProject = pIndex;
      renderGrid(projects[currentProject].images);
    }
  });

  eventManager.subscribe('grid-mode', (pIndex) => {
    currentProject = pIndex;
    Array.from(DomElement.children).forEach(emptyElement);
    renderGrid(projects[currentProject].images);
    DomElement.style.display = null;
  });

  return DomElement;
};

module.exports = Grid;
