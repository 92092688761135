const { html } = require('@forgjs/noframework');
const Nav = require('../components/Nav2');
const Footer = require('../components/Footer');
const Links = require('../components/links');

const AboutMe = (nav = Nav()) => {
  const DomElement = html`
  <div class="about-me">
    ${nav}
    <div class="about-me__container">
      <div>
        <img class="about-me__image" src="/img/selfp.jpg" loading="lazy"/>
        <div class="about-me__credit">Photo Credit: Kevin Alexander</div>
      </div>
      <div class="about-me__text-content">
        <h1 class="about-me__name">Spandita Malik</h1>
        <p>
          Spandita Malik is a visual artist from India. Her work is concerned with the current global socio-political state of affairs with an emphasis on women’s rights and gendered violence. Malik specializes in process based work in photography, recently with photographic surface embroideries and collaborations with women in India. Malik’s work in expanded documentary and social-practice consciously emanates from the idea of decolonising the eye and aesthetic surrounding documentary photography of India.         
        </p>
        <p>
          Malik received her MFA in Photography from Parsons School of Design in 2019, where she was awarded the Dean’s Merit Scholarship, Photography Programmatic Scholarship and Graduate Travel Grant Award. She has been awarded The 30: New and Emerging Photographers Award (2022); En Foco Photography Fellowship (2021) and Firecracker Photographic Grant (2020). Malik was nominated for the Foam Paul Huf Award (2020) and was a finalist for the Aperture Portfolio Prize (2021). She was chosen for The Silver Arts Projects Residency, NY (2021); The Center for Photography at Woodstock Artist in Residency Program, Woodstock, NY (2021); Bemis Center of Contemporary Arts (2021); Baxter St Workspace Residency in New York (2020); Feminist Incubator Residency by Project for Empty Spaces in New Jersey (2020). Malik’s work has been featured in Artsy, Art Spiel, Buzzfeed, Crafts Magazine, Musée Magazine, Harper’s Magazine and Elephant Magazine, she was named ‘Ones to Watch 2020’ by British Journal of Photography. Her work has been exhibited internationally in China, France, Germany, India, Italy, New York, New Zealand, Russia, UAE and UK. Malik is currently an AICAD Post Graduate Teaching Fellow at Kansas City Art Institute.
        <br/>
        <br/>
        <br/>
        </p>

        <p>
          CONTACT INFO <br>
          <br>
          Inquiries:<br>
            <a href="mailto:spandita.malik@gmail.com">spandita.malik@gmail.com</a>
        </p>

      </div>
    </div>
    ${Footer()}
    ${Links()}
  </div>`;

  return DomElement;
};

module.exports = AboutMe;
