const rooterEmiter = require('../rooterEmiter');

const link = (url, element, eventManager, event) => {
  element.addEventListener('click', () => {
    rooterEmiter.emit('reroot', url);
    window.history.pushState(null, url, url);
    if (eventManager) {
      eventManager.emit(event, url);
    }
  });
  return element;
};

module.exports = link;
