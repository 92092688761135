const { html } = require('@forgjs/noframework');
const link = require('../components/link');
const Nav = require('../components/Nav2');

module.exports = (() => {
  const DomElement = html`<div class="container p404">
    ${Nav()}
    <h2>This page is missing</h2>
      <h1>404</h1>
      ${link('/', html`<a>Home</a>`)}
    </div>`;
  return DomElement;
})();
