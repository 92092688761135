const { html } = require('@forgjs/noframework');

const Footer = () => {
  const DomElement = html`<footer>
  <div class="footer__container">
    © ${(new Date()).getFullYear()} Spandita Malik. All right reserved.
  </div>
</footer>`;

  return DomElement;
};

module.exports = Footer;
